import React from 'react';

import MainTitle from '../_ui/MainTitle/MainTitle';
import MarketingMainChart from './MarketingMainChart/MarketingMainChart';

const MarketingScreen = () => {
    return (
        <div id="dash-comercial">
            <MainTitle title="Marketing" />
            <MarketingMainChart />
        </div>
    );
};

export default MarketingScreen;
