import React, { useState } from 'react';
import { newPassword } from '../../../actions/auth.action';
import useForm from '../../../hooks/useForm';

const PasswordRenew = () => {
    const [message, setMessage] = useState({ on: false, message: '' });

    const { values, handleInputChange } = useForm({});
    const handlePasswordChange = async (e) => {
        setMessage({ on: false, message: `` });
        e.preventDefault();
        const newPasswordUser = await newPassword(values.email);
        if (!newPasswordUser) {
            setMessage({ on: true, message: `Ha ocurrido un error, inténtalo de nuevo` });
        } else {
            setMessage({ on: true, message: `Revisa tu email` });
        }
    };

    return (
        <>
            <section className="login">
                <figure>
                    <img src={ process.env.REACT_APP_COMPANY_LOGO_BIG } alt="" />
                </figure>
                <p>Recuérdanos tu correo</p>
                <form onSubmit={ handlePasswordChange }>
                    <label>Email</label>
                    <input type='email' onChange={ handleInputChange } placeholder='Tu email' name='email' />
                    <button type='submit' className='my-btn mini'>
                        Cambiar contraseña
                    </button>
                </form>
                { message.on && <p className='p3-13'>{ message.message }</p> }
            </section>

        </>
    );
};

export default PasswordRenew;