import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { clubCountByCountry } from '../../../actions/general-data.action';

import Box from '../../_ui/Box/Box';

import { colorArray } from '../../../helpers/colors';
import { formatNumber, getCountry } from '../../../helpers/_helper';

import { ResponsivePie } from '@nivo/pie';
import { MdChevronRight } from 'react-icons/md';

const CountryChart = () => {
    const [clubsCountry, setClubsCountry] = useState([]);
    const isMounted = useRef(true);
    const [data, setData] = useState([]);

    const getClubsByCountry = useCallback(async () => {
        const countryCount = [];
        const getData = await clubCountByCountry();
        getData?.forEach((country, idx) => {
            countryCount.push(Number(country.count));
            if (data?.length === 0) {
                setData(data => [...data, { id: getCountry(country.country), label: getCountry(country.country), value: country.count, color: colorArray[idx] }]);
            }
        });
        setClubsCountry(getData);
    }, [data]
    );

    useEffect(() => {
        if (isMounted.current) {
            getClubsByCountry();
        }
        return () => {
            isMounted.current = false;
        };
    }, [getClubsByCountry]);

    return (
        <Box id="country-chart" title="Países">
            <Link className="client-list-link" to="/comercial/paises"> Listado de países  <MdChevronRight /></Link>
            <div ref={ isMounted } className="bottom">
                {
                    clubsCountry?.length > 0 && (
                        <>
                            <div className="country-table">
                                {
                                    clubsCountry?.map(country => (
                                        <article className="country-row" key={ country.countryId }>
                                            <h5 className="country">{ getCountry(country.country) }</h5>
                                            <span className="count p3-13">{ formatNumber(country.count) }</span>
                                        </article>
                                    ))
                                }
                            </div>
                            <div className="fix">
                                <ResponsivePie
                                    colors={ { scheme: 'paired' } }
                                    data={ data }
                                    margin={ { top: 0, right: 24, bottom: 80, left: 24 } }
                                    innerRadius={ 0.3 }
                                    activeOuterRadiusOffset={ 8 }
                                    borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                                    enableArcLinkLabels={ false }
                                    arcLinkLabelsSkipAngle={ 10 }
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={ 2 }
                                    arcLinkLabelsColor={ { from: 'color' } }
                                    arcLabelsSkipAngle={ 10 }
                                    arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                                    legends={ [] }
                                />
                            </div>
                        </>
                    )
                }
            </div>
        </Box>
    );
};

export default CountryChart;
