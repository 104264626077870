import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';

const LineGraph = ({ graph, pending }) => {

    const [data, setData] = useState([{
        "users": "",
        "Activos": 0,
        "Pendientes": 0,
    }]);

    useEffect(() => {
        let aux = {
            "users": "",
            "Activos": graph.actives,
            "Pendientes": graph.pending,
        };
        setData([aux]);
    }, [graph]);

    return (
        <article className='line-graph'>
            <p>Usuarios</p>
            { !pending &&
                <ResponsiveBar
                    data={ data }
                    keys={ [
                        'Pendientes',
                        'Activos',
                    ] }
                    indexBy="users"
                    colors={ ['#F98600', '#00BA34'] }
                    margin={ { top: 0, right: 0, bottom: 50, left: 0 } }
                    padding={ 0.1 }
                    layout="horizontal"
                    valueScale={ { type: 'linear' } }
                    indexScale={ { type: 'band', round: true } }
                    borderRadius={ 0 }
                    axisTop={ null }
                    axisRight={ null }
                    axisBottom={ null }
                    enableLabel={ false }
                    axisLeft={ null }
                    enableGridY={ false }
                    labelSkipWidth={ 12 }
                    labelSkipHeight={ 12 }
                    legends={ [
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-left',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: 32,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 12,
                            symbolShape: 'square',
                        }
                    ] }
                /> }
        </article>
    );
};

export default React.memo(LineGraph);