import { fetchConToken, fetchConTokenClupik } from '../helpers/fetch';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { lastThirtyDays } from '../helpers/dates';

export const getClubInfo = async (clubId) => {
	try {
		const resp = await fetchConToken(`club/${clubId}`, 'GET');
		const body = await resp.json();
		if (body.ok) {
			return body.club;
		} else {
			const errorMsg = body.msg;
			Swal.fire('Error', errorMsg, 'error');
			return false;
		}
	} catch (error) {
		console.log('getClubInfo', error);
		return false;
	}
};

export const searchClub = async (search) => {
	const regex = /[^\sa-zA-Z0-9À-ú]{1,100}/gi;
	let query = search.replace(regex, '');
	try {
		const resp = await fetchConToken(`club/search?clubSearch=${query}`, 'GET');
		const body = await resp.json();
		if (body.ok) {
			return body.clubs;
		} else {
			const errorMsg = body.msg;
			Swal.fire('Error', errorMsg, 'error');
			return false;
		}
	} catch (error) {
		console.log('getClubInfo', error);
		return false;
	}
};

export const usersByRoleByClub = async (clubId) => {
	try {
		const resp = await fetchConToken(`club/${clubId}/users`, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const admonByClub = async (clubId, season) => {
	try {
		const resp = await fetchConToken(`club/${clubId}/admon?season=${season}`, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const comunicationByClub = async (clubId) => {
	try {
		const resp = await fetchConToken(`club/${clubId}/comunication`, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const clubNotifications = async (clubId) => {
	try {
		const resp = await fetchConTokenClupik(`clubs/${clubId}/messagesSended`);
		let body = await resp.json();
		body = body.filter((elm) => lastThirtyDays() <= elm.createdAt);
		return body;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const clupikCompetition = async (clubId, seasonId) => {
	let today = dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
	let ago = lastThirtyDays();

	try {
		const resp = await fetchConTokenClupik(`clubs/${clubId}/stats/organizer?seasonIds=${seasonId}&from=${ago}&to=${today}`);
		const body = await resp.json();
		delete body.from;
		delete body.to;
		body.games = body.games.length;
		return body;
	} catch (error) {
		console.log('clupikCompetition', error);
		return false;
	}
};

export const clubCompetition = async (clubId, seasonId) => {
	try {
		const resp = await fetchConToken(`club/${clubId}/competition?season=${seasonId}`, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log('clubCompetition', error);
		return false;
	}
};

export const clubTraining = async (clubId, seasonId) => {
	try {
		const resp = await fetchConToken(`club/${clubId}/training?season=${seasonId}`, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log('clubTraining', error);
		return false;
	}
};

export const clubStore = async (clubId, seasonId) => {
	try {
		const resp = await fetchConToken(`club/${clubId}/store`, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log('clubStore', error);
		return false;
	}
};
