import React, { useEffect, useRef, useState } from 'react';
import { clubStore } from '../../../../../actions/club.action';
import Alert from '../../../../_ui/Alert/Alert';
import Box from '../../../../_ui/Box/Box';
import Spinner from '../../../../_ui/Spinner/Spinner';

const ClubStore = ({ points, module, clubId, coin }) => {
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [store, setStore] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const getTrainings = async () => {
            setStore({});
            let data = await clubStore(clubId);
            if (data) {
                setStore(data.store);
            }
            setLoading(false);
        };
        if (isMounted.current) {
            clubId && getTrainings();
        }
    }, []);

    return (
        <Box title={ 'Tienda online' } id="club-store" points={ points }>
            {
                module ? (
                    loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className="preview">
                                <div className="each">
                                    <h6 className="h6-14-b">Total de ventas (Últimos 180 días)</h6>
                                    <p className="p4-14">{ store?.purchases?.purchases } pedido(s) - { Number(store?.purchases?.value) / 100 }{ coin }</p>
                                </div>
                                <div className="each">
                                    <h6 className="h6-14-b">Productos</h6>
                                    <p className="p4-14">{ store?.categories } categoría(s) - { store?.products } producto(s)</p>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <Alert text='Sin resultados. Este club no tiene activado el módulo de tienda.' />

                )
            }
        </Box>
    );
};

export default React.memo(ClubStore);