import React, { useEffect, useRef, useState } from 'react';
import { clubNotifications, comunicationByClub } from '../../../../../actions/club.action';
import { ResponsivePie } from '@nivo/pie';
import Box from '../../../../_ui/Box/Box';
import { FaBan, FaEnvelope, FaHeart, FaMobileAlt, FaRegComment } from 'react-icons/fa';
import Spinner from '../../../../_ui/Spinner/Spinner';

const ClubCom = ({ clubId, points, module }) => {
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [com, setCom] = useState(null);

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const getCom = async () => {
            const data = await comunicationByClub(clubId);
            const nots = await clubNotifications(clubId);
            if (data) {
                delete data.ok;
                if (nots) {
                    data.nots = nots;
                } else {
                    data.nots = null;
                }
                setCom(data);
                setLoading(false);
            }
        };
        isMounted.current && getCom();
    }, []);

    return (
        <Box subtitle={ 'Últimos 30 días' } expandable title="Comunicación" id="club-com" points={ points }>
            {
                loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="preview">
                            <div className="each">
                                <h6 className="h6-14-b">Notificaciones</h6>
                                <p className="p4-14">
                                    { com && <span className="p4-14">{ com.nots ? com.nots?.length : 0 } notificaciones enviadas</span> }
                                </p>
                            </div >
                            <div className="each">
                                <h6 className="h6-14-b">Publicaciones</h6>
                                { com && <span className="p4-14">{ com.publications?.pubs } publicaciones - { com.publications?.coms } comentarios</span> }
                            </div>
                            <div className="each">
                                <h6 className="h6-14-b">Patrocinadores</h6>
                                { com && <span className="p4-14">{ com.sponsors?.sponsor } patrocinadores - { com.sponsors?.cats } categorías</span> }
                            </div>
                        </div >
                        <div className="view">
                            <div className="graphs">
                                <div className="left">
                                    <h5 className='h5-16-b'>Publicaciones</h5>
                                    <div className="list">
                                        <p className="p4-14 each">
                                            <FaRegComment />
                                            <strong>Comentarios totales: </strong>
                                            { com?.publications?.coms }
                                        </p>
                                        <p className="p4-14 each">
                                            <FaHeart />
                                            <strong>Likes recibidos: </strong>
                                            { com?.publications?.likes }
                                        </p>
                                        <p className="p4-14 each">
                                            <FaBan />
                                            <strong>Publicaciones denunciadas: </strong>
                                            { com?.publications?.informed }
                                        </p>
                                    </div>
                                    { com?.publications && <ResponsivePie
                                        data={ [{ id: 'De los usuarios', label: 'De los usuarios: ' + com.publications?.userpub, value: com.publications?.userpub },
                                        { id: 'Del club', label: 'Del club: ' + com.publications?.clubpub, value: com.publications?.clubpub }
                                        ] }
                                        colors={ ['#FBEC66', '#FBB666', '#0085FF'] }
                                        margin={ { top: 0, right: 0, bottom: 24, left: 0 } }
                                        innerRadius={ 0.5 }
                                        activeOuterRadiusOffset={ 0 }
                                        borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                                        enableArcLabels={ false }
                                        enableArcLinkLabels={ false }
                                        arcLinkLabelsSkipAngle={ 10 }
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={ 2 }
                                        arcLinkLabelsColor={ { from: 'color' } }
                                        arcLabelsSkipAngle={ 10 }
                                        arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                                        legends={ [
                                            {
                                                anchor: 'left',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 0,
                                                translateY: 0,
                                                itemsSpacing: 0,
                                                itemWidth: 400,
                                                itemHeight: 29,
                                                itemTextColor: '#1c1c1c',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 12,
                                                symbolShape: 'square',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemTextColor: '#000'
                                                        }
                                                    }
                                                ]
                                            }
                                        ] }
                                    />
                                    }
                                </div>
                                <div className="right">
                                    <h5 className='h5-16-b'>Notificaciones</h5>
                                    <div className="list">
                                        <p className="p4-14 each">
                                            <FaEnvelope />
                                            <strong>Email: </strong>
                                            { com?.nots?.filter(elm => elm.channels.includes('MAIL')).length }
                                        </p>
                                        <p className="p4-14 each">
                                            <FaMobileAlt />
                                            <strong>Móvil: </strong>
                                            { com?.nots?.filter(elm => elm.channels.includes('PUSH')).length }
                                        </p>
                                    </div>

                                    { com?.nots && <ResponsivePie
                                        data={ [{ id: 'Publicaciones', label: 'Publicaciones: ' + com.nots.filter(elm => elm.type === 'PUB').length, value: com.nots.filter(elm => elm.type === 'PUB').length },
                                        { id: 'Grupo Usuarios', label: 'Grupo Usuarios: ' + com.nots.filter(elm => elm.type === 'MSG').length, value: com.nots.filter(elm => elm.type === 'MSG').length },
                                        { id: 'Todos', label: 'Todos: ' + com.nots.filter(elm => elm.type === 'ALL').length, value: com.nots.filter(elm => elm.type === 'ALL').length }
                                        ] }
                                        colors={ ['#F98600', '#00BA34', '#0085FF'] }
                                        margin={ { top: 0, right: 0, bottom: 24, left: 0 } }
                                        innerRadius={ 0.5 }
                                        activeOuterRadiusOffset={ 0 }
                                        borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                                        enableArcLabels={ false }
                                        enableArcLinkLabels={ false }
                                        arcLinkLabelsSkipAngle={ 10 }
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={ 2 }
                                        arcLinkLabelsColor={ { from: 'color' } }
                                        arcLabelsSkipAngle={ 10 }
                                        arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                                        legends={ [
                                            {
                                                anchor: 'left',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 0,
                                                translateY: 0,
                                                itemsSpacing: 0,
                                                itemWidth: 400,
                                                itemHeight: 29,
                                                itemTextColor: '#1c1c1c',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 12,
                                                symbolShape: 'square',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemTextColor: '#000'
                                                        }
                                                    }
                                                ]
                                            }
                                        ] }
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                    </>

                )
            }
        </Box>
    );
};

export default React.memo(ClubCom);