import { fetchConToken } from '../helpers/fetch';

export const getCountriesTable = async () => {
	const resp = await fetchConToken('general-data/country', 'GET');
	const body = await resp.json();
	if (body.ok) {
		let result = body.result.map((elm) => elm.total !== '0' && elm);
		result = result.filter((elm) => elm);
		return result;
	} else {
		const errorMsg = body.err;
		console.log(errorMsg);
	}
};

export const getCountriesGraph = async (qt, state) => {
	let url;
	if (state) {
		url = `general-data/country/graph?amount=${qt}&state=${state}`;
	} else {
		url = `general-data/country/graph?amount=${qt}`;
	}
	const resp = await fetchConToken(url, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.result;
	} else {
		const errorMsg = body.err;
		console.log(errorMsg);
	}
};
