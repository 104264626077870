import dayjs from 'dayjs';
export const meses = {
	0: 'Ene',
	1: 'Feb',
	2: 'Mar',
	3: 'Abr',
	4: 'May',
	5: 'Jun',
	6: 'Jul',
	7: 'Ago',
	8: 'Sep',
	9: 'Oct',
	10: 'Nov',
	11: 'Dic',
};

export const firstDayInCurrentMonth = () => {
	const date = new Date();
	return dayjs(date).clone().startOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
};

export const lastDayInCurrentMonth = (yourDate) => {
	if (!yourDate) {
		const date = new Date();
		return dayjs(date).clone().endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
	}
	const date = new Date(yourDate);
	return dayjs(date).clone().endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
};

export const firstDay = (backMonths) => {
	const date = new Date();
	return dayjs(new Date(date.getFullYear(), date.getMonth() - backMonths, 1))
		.clone()
		.startOf('month')
		.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
};
export const lastDay = (backMonths) => {
	const date = new Date();
	return dayjs(new Date(date.getFullYear(), date.getMonth() - backMonths))
		.clone()
		.endOf('month')
		.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
};

export const getToday = () => {
	return new Intl.DateTimeFormat('es-En', { dateStyle: 'full', timeStyle: 'long' }).format(new Date());
};

export const lastThirtyDays = () => {
	let today = new Date();
	let priorDate = new Date(new Date().setDate(today.getDate() - 30));

	return dayjs(priorDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
};
