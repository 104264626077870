import React, { useEffect, useState } from 'react';

import Spinner from '../../../_ui/Spinner/Spinner';

import { formatNumber } from '../../../../helpers/_helper';

import DataTable from 'react-data-table-component';

const CustomRole = ({ row }) => {
    const [rol, setRol] = useState('');
    useEffect(() => {
        switch (row.rol) {
            case 'admin':
                setRol('Administrador');
                break;
            case 'coaches':
                setRol('Entrenador');
                break;
            case 'players':
                setRol('Jugador');
                break;
            case 'accountant':
                setRol('Contable');
                break;
            case 'editor':
                setRol('Editor');
                break;
            case 'coordinator':
                setRol('Coordinador');
                break;
            case 'tutor':
                setRol('Tutor');
                break;
            default:
                break;
        }
    }, [row]);
    return (
        <>{ rol }</>
    );
};

const UsersRolTable = ({ tableData, pending }) => {

    const columns = [
        {
            name: 'Rol',
            selector: row => row.rol,
            sortable: true,
            cell: row => <CustomRole row={ row } />,
        },
        {
            name: 'Activos',
            selector: row => row.active,
            sortable: true,
            right: true,
            cell: row => row.active && row.active !== '0' ? `${formatNumber(row.active)} (${Math.round((row.active / row.total) * 100)}%)` : 0 + '(0%)'
        },
        {
            name: 'Pendientes',
            selector: row => row.pending,
            sortable: true,
            right: true,
            cell: row => row.pending && row.pending !== '0' ? `${formatNumber(row.pending)} (${Math.round((row.pending / row.total) * 100)}%)` : 0 + '(0%)'
        },
        {
            name: 'Base de datos',
            selector: row => row.noemail,
            sortable: true,
            right: true,
            cell: row => row.noemail && row.noemail !== '0' ? `${formatNumber(row.noemail)} (${Math.round((row.noemail / row.total) * 100)}%)` : 0 + '(0%)'
        },
        {
            name: 'Bloqueados',
            selector: row => row.locked,
            sortable: true,
            right: true,
            cell: row => row.locked && row.locked !== '0' ? `${formatNumber(row.locked)} (${Math.round((row.locked / row.total) * 100)}%)` : 0 + '(0%)'

        },
        {
            name: 'Total',
            selector: row => formatNumber(row.total),
            sortable: true,
            right: true,
        },

    ];


    return (
        <>
            <DataTable
                columns={ columns }
                data={ tableData }
                highlightOnHover
                pointerOnHover
                fixedHeader
                progressPending={ pending }
                progressComponent={ <Spinner /> }
            />
        </>
    );
};

export default React.memo(UsersRolTable);
