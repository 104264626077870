import { types } from '../../types/types';

export const ClientsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.clientsCreate:
			return action.payload;
		default:
			return state;
	}
};
