import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { getClients } from '../../../actions/business.action';

import Box from '../../_ui/Box/Box';
import StateBadge from '../../_ui/StateBadge/StateBadge';
import MainTitle from '../../_ui/MainTitle/MainTitle';
import BackButton from '../../_ui/BackButton/BackButton';
import Spinner from '../../_ui/Spinner/Spinner';

import dayjs from 'dayjs';
import DataTable from 'react-data-table-component';
import { ClientsContext } from '../../../reducers/clients/ClientsContext';
import { types } from '../../../types/types';
import TableFilter from '../../_ui/TableFilter/TableFilter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ExportCSV from '../../_ui/ExportCSV/ExportCSV';
import { getToday } from '../../../helpers/dates';
import PointsBadge from '../../_ui/PointsBadge/PointsBadge';

const Clientes = () => {
	const isMounted = useRef(true);

	const columns = [
		{
			name: 'ClubId',
			selector: (row) => (row.clubId ? Number(row.clubId) : '-'),
			sortable: true,
			reorder: true,
			grow: 0,
		},
		{
			name: 'Club',
			selector: (row) => row.club,
			sortable: true,
			reorder: true,
			grow: 3,
		},
		{
			name: 'Uso',
			selector: (row) => Number(row.points),
			sortable: true,
			reorder: true,
			cell: (row) => <PointsBadge points={ row.points } compare='/60' />
		},
		{
			name: 'Fecha Creación',
			selector: (row) => row.t_creado,
			sortable: true,
			reorder: true,
			cell: (row) => (row.t_creado ? <span>{ dayjs(row.t_creado).format('DD/MM/YYYY') }</span> : <span>-</span>),

		},
		{
			name: 'Fecha Firma',
			selector: (row) => row.t_won,
			sortable: true,
			reorder: true,
			cell: (row) => (row.t_won ? <span style={ { color: '#009dff' } }>{ dayjs(row.t_won).format('DD/MM/YYYY') }</span> : <span>-</span>),

		},
		{
			name: 'Cierre esperado',
			selector: (row) => row.t_expected,
			sortable: true,
			reorder: true,
			cell: (row) => <span style={ { color: '#5c32a8' } }>{ dayjs(row.t_close).format('DD/MM/YYYY') }</span>,

		},
		{
			name: 'Fecha Alta',
			selector: (row) => row.t_close,
			sortable: true,
			reorder: true,
			cell: (row) => (row.t_alta ? <span style={ { color: '#00ba34' } }>{ dayjs(row.t_close).format('DD/MM/YYYY') }</span> : <span>-</span>),

		},
		{
			name: 'Fecha Baja',
			selector: (row) => row.t_lost,
			sortable: true,
			reorder: true,
			cell: (row) => (row.t_lost ? <span style={ { color: '#e92c2c' } }>{ dayjs(row.t_lost).format('DD/MM/YYYY') }</span> : <span>-</span>),

		},
		{
			name: 'Tipo de pago',
			selector: (row) => (row.payment ? (row.payment === '285' ? 'Anual' : 'Mensual') : 'N/S'),
			sortable: true,
			reorder: true,

		},
		{
			name: 'Cuota',
			selector: (row) => row.cuota,
			sortable: true,
			reorder: true,
			cell: (row) => <span style={ { color: '#00ba34' } }>{ row.cuota }</span>,

		},
		{
			name: 'Ingresos (IVA)',
			selector: (row) => Number(row.income),
			sortable: true,
			reorder: true,
			cell: (row) => (<span style={ { color: '#00ba34' } }>{ row.income && row.income !== '-' ? Number(row.income).toFixed(2) + '€' : '-' }</span>),

		},
		{
			name: 'Estado',
			selector: (row) => row.state,
			sortable: true,
			reorder: true,
			cell: (row) => <StateBadge row={ row } />,

		},
	];

	const [tableData, setTableData] = useState([]);
	const [pending, setPending] = useState(true);
	const { clients, clientsDispatch } = useContext(ClientsContext);

	const getFirstClients = useCallback(async () => {
		setPending(true);
		if (clients.length > 0) {
			setTableData(clients);
		} else {
			let getData = await getClients();
			if (getData?.length > 0) {
				clientsDispatch({ type: types.clientsCreate, payload: getData });
				setTableData(getData);
			}
		}
		setPending(false);
	}, [clients, clientsDispatch]);

	useEffect(() => {
		if (isMounted.current) {
			getFirstClients();
		}
		return () => {
			isMounted.current = false;
		};
	}, [getFirstClients]);

	const paginationOptions = {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
	};
	///Filter
	const [filterText, setFilterText] = useState('');
	const filteredItems = tableData.filter(
		item => item.club && item.club.toLowerCase().includes(filterText.toLowerCase()),
	);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setFilterText('');
			}
		};

		return (
			<>
				<ExportCSV array={ tableData } fileName={ `Clientes Clupik a fecha ${getToday()}` } />
				<TableFilter title="Filtrar por club" onFilter={ e => setFilterText(e.target.value) } filterText={ filterText } onClear={ handleClear } />
			</>
		);
	}, [filterText, tableData]);


	//RowClick
	const history = useHistory();
	const openClub = (club) => {
		history.push(`/comercial/clientes/club/${club.clubId}`);
	};

	return (
		<>
			<BackButton title='Comercial' link='/comercial' />
			<section id='clientes'>
				<MainTitle title='Clientes' />
				<Box title='Clientes'>

					<DataTable
						columns={ columns }
						data={ filteredItems }
						highlightOnHover
						pointerOnHover
						fixedHeader
						progressPending={ pending }
						progressComponent={ <Spinner message={ 'Cargando clientes. Esta operación puede tardar unos minutos. :)' } /> }
						pagination
						paginationComponentOptions={ paginationOptions }
						subHeader
						subHeaderComponent={ subHeaderComponentMemo }
						onRowClicked={ (event) => openClub(event) }
					/>
				</Box>
			</section>
		</>
	);
};

export default Clientes;
