import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { getContractGraph } from '../../../actions/contracts.action';
import Dropdown from '../../_ui/DropDown/DropDown';
import Spinner from '../../_ui/Spinner/Spinner';
import Box from '../../_ui/Box/Box';
import { dropList, dropListClubs } from '../../../helpers/droplist';
import { getClientsCount } from '../../../actions/business.action';
import { firstDay } from '../../../helpers/dates';
import { formatNumber } from '../../../helpers/_helper';
import ContratosTable from './ContratosTable';
import Acclaration from '../../_ui/Acclaration/Acclaration';


const ContratoChart = () => {
    const [data, setData] = useState([]);
    const [clubState, setClubState] = useState({ id: 4, name: 'Clubs Activos' });
    const [pendingGraph, setPendingGraph] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [amount, setAmount] = useState({ id: 6, name: "Últimos 6 meses" });
    const [wonClubs, setWonClubs] = useState(0);
    const [lostCClubs, setLostCClubs] = useState(0);
    const [lostNClubs, setLostNClubs] = useState(0);
    const [trialClubs, setTrialClubs] = useState(0);


    const getGraph = async (qt, state) => {
        const dataFromEndpoint = await getContractGraph(qt, state);
        let tenGraph = {
            id: "Clupik10",
            data: dataFromEndpoint.ten.reverse()
        };
        setData(data => [...data, tenGraph]);
        let twentyGraph = {
            id: "Clupik20",
            data: dataFromEndpoint.twenty.reverse()
        };
        setData(data => [...data, twentyGraph]);
        let plusGraph = {
            id: "Clupik+",
            data: dataFromEndpoint.plus.reverse()
        };
        setData(data => [...data, plusGraph]);
        let freeGraph = {
            id: "Free",
            data: dataFromEndpoint.free.reverse()
        };
        setData(data => [...data, freeGraph]);
        setPendingGraph(false);
    };

    const getClientsdata = async (qt) => {
        const data = await getClientsCount(firstDay(qt), qt);
        const won = data.won;
        let wonAdd = won?.length > 0 ? won.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setWonClubs(wonAdd?.count);

        const trial = data.trial;
        let trialAdd = trial?.length > 0 ? trial.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setTrialClubs(trialAdd?.count);

        const lostC = data.lostC;
        let lostCAdd = lostC?.length > 0 ? lostC.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setLostCClubs(lostCAdd?.count);

        const lostN = data.lostN;
        let lostNAdd = lostN?.length > 0 ? lostN.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setLostNClubs(lostNAdd?.count);
        setDataLoading(false);
    };


    const selectClubState = (id) => {
        setPendingGraph(true);
        setData([]);
        setClubState(c => ({ ...c, id: id }));
        getGraph(amount.id, id);
    };

    const selectClubStateName = (name) => {
        setClubState(c => ({ ...c, name: name }));
    };

    const selectAmountTime = (id) => {
        setDataLoading(true);
        setPendingGraph(true);
        setData([]);
        setAmount(a => ({ ...a, id: id }));
        getGraph(id, clubState.id);
        getClientsdata(id);
    };
    const selectAmountTimeName = (name) => {
        setAmount(a => ({ ...a, name: name }));
    };

    useEffect(() => {
        setDataLoading(true);
        setPendingGraph(true);
        getClientsdata(6);
        getGraph(6, 4);
    }, []);

    return (
        <Box id='client-chart' title='Clientes por país'>
            <div className="drops">
                <Dropdown list={ dropListClubs } method={ selectClubState } currentSelection={ clubState.name } setCurrentSelection={ selectClubStateName } />
                <Dropdown list={ dropList } method={ selectAmountTime } currentSelection={ amount.name } setCurrentSelection={ selectAmountTimeName } />
            </div>
            { pendingGraph ? (
                <Spinner />
            ) : (

                <div className="fix">
                    <article className="left">
                        <ResponsiveLine
                            data={ data }
                            margin={ { top: 5, right: 10, bottom: 100, left: 30 } }
                            xScale={ { type: 'point' } }
                            yScale={ { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false } }
                            valueScale={ { type: "symlog" } }
                            yFormat=">-0,.2~f"
                            axisTop={ null }
                            enableSlices="x"
                            axisRight={ null }
                            axisBottom={ {
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 10,
                                tickRotation: -90,
                                // legend: 'Mes',
                                legendOffset: 0,
                                legendPosition: 'middle'
                            } }
                            axisLeft={ {
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                // legend: 'Clubs añadidos',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            } }
                            colors={ { scheme: 'set3' } }
                            enableGridX={ false }
                            pointSize={ 10 }
                            pointColor={ { theme: 'background' } }
                            pointBorderWidth={ 2 }
                            pointBorderColor={ { from: 'serieColor' } }
                            pointLabelYOffset={ -12 }
                            useMesh={ true }
                            legends={ [
                                {
                                    anchor: 'bottom-left',
                                    direction: 'row',
                                    justify: false,
                                    translateX: -16,
                                    translateY: 90,
                                    itemWidth: 91,
                                    itemHeight: 10,
                                    itemsSpacing: 4,
                                    symbolSize: 10,
                                    symbolShape: 'circle',
                                    itemDirection: 'left-to-right',
                                    itemTextColor: '#777',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ] }
                        />
                    </article>
                    <article className='right'>
                        { !dataLoading ? (

                            <div className='data'>
                                <div className='addition'>
                                    <h1>{ formatNumber(wonClubs) }</h1>
                                    <div>
                                        <small className='p2-16'>Activos</small>
                                    </div>
                                </div>
                                <div className='addition'>
                                    <h2>{ formatNumber(trialClubs) }</h2>
                                    <div>
                                        <small className='p2-16'>Trial</small>
                                    </div>
                                </div>
                                <div className='addition'>
                                    <h2>{ formatNumber(lostCClubs) }</h2>
                                    <div>
                                        <small className='p2-16'>Baja Consolidada</small>
                                    </div>
                                </div>
                                <div className='addition'>
                                    <h2>{ formatNumber(lostNClubs) }</h2>
                                    <div>
                                        <small className='p2-16'>Baja No Consolidada</small>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        ) }

                    </article>
                </div>
            ) }
            <Acclaration title="Detalle por contrato" text="Información de tipo de contrato por estado de club" />
            <ContratosTable />
        </Box>
    );
};

export default ContratoChart;
