import React, { useContext } from 'react'
import { AuthContext } from '../../reducers/auth/AuthContext'
import MainTitle from '../_ui/MainTitle/MainTitle'

const DashboardScreen = () => {
	const { user } = useContext(AuthContext)
	return (
		<>
			<MainTitle title="Dashboard" subtitle={ `Good to see you again ${user.name}` } />
		</>
	)
}

export default DashboardScreen
