import { useEffect, useReducer, useRef } from 'react';
import { AuthContext } from './reducers/auth/AuthContext';
import { AuthReducer } from './reducers/auth/authReducer';
import { ClientsReducer } from './reducers/clients/clientsReducer';
import { ClientsContext } from './reducers/clients/ClientsContext';
import './global.scss';
import AppRouter from './routers/AppRouter';

const init = () => {
	return JSON.parse(localStorage.getItem('clupik-user')) || { logged: false };
};


const App = () => {
	const isMounted = useRef(true);
	const [user, dispatch] = useReducer(AuthReducer, {}, init);
	const [clients, clientsDispatch] = useReducer(ClientsReducer, []);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (isMounted.current) {
			localStorage.setItem('clupik-user', JSON.stringify(user));
		}
	}, [user]);

	return (
		<>
			<AuthContext.Provider value={ { user, dispatch } }>
				<ClientsContext.Provider value={ { clients, clientsDispatch } }>
					<AppRouter />
				</ClientsContext.Provider>
			</AuthContext.Provider>
		</>
	);
};

export default App;
