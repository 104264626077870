import React from 'react';
import styled from 'styled-components';
import {
    BsFillTrashFill
} from "react-icons/bs";
const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
    margin-bottom:0;
	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled.button`
	border-radius: 0 5px 5px 0;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    border:none;
    background-color:#ddd;
    color:white;
    cursor:pointer;
`;

const TableFilter = ({ filterText, onFilter, onClear, title }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder={ title }
            aria-label="Search Input"
            value={ filterText }
            onChange={ onFilter }
        />
        <ClearButton type="button" onClick={ onClear } >
            <BsFillTrashFill />
        </ClearButton>
    </>
);

export default TableFilter;
