import React from 'react';

import MainTitle from '../../_ui/MainTitle/MainTitle';
import SportStructureTable from './SportStructureTable/SportStructureTable';

const SportStructure = () => {
    return (
        <div id="dash-comercial">
            <MainTitle title="Estructura deportiva" />
            <SportStructureTable />
        </div>
    );
};

export default SportStructure;
