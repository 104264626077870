import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { types } from '../../../types/types.js';
import { AuthContext } from '../../../reducers/auth/AuthContext.js';

import { hasToken } from './../../../actions/auth.action.js';
import { updateClupikMetricTable, updatePoints } from '../../../actions/clupik.actions.js';

import { MdDashboard, MdFlag, MdAttachFile, MdGroup, MdDevices } from 'react-icons/md';
// import { MdDashboard, MdAttachMoney, MdFlag, MdAttachFile, MdSettings, MdGroup, MdDevices } from 'react-icons/md';
import { IoBasketballSharp } from 'react-icons/io5';
import ClubSearch from '../ClubSearch/ClubSearch.jsx';

const DashNav = ({ handleShow }) => {
    const [currentNav, setCurrentNav] = useState('');
    const { user, dispatch } = useContext(AuthContext);

    const location = useLocation();

    const handleLogout = useCallback(async () => {
        await dispatch({ type: types.logout });
        localStorage.removeItem('clupik-token');
        localStorage.removeItem('clupik-user');
    }, [dispatch]);

    const checkUser = useCallback(async () => {
        const token = await hasToken();
        if (!token) {
            handleLogout();
        }
    }, [handleLogout]);

    // const updatePipe = async () => {
    //     await updateLostDeals();
    //     await updateOrganizationData();
    // };

    const updateData = async () => {
        await updateClupikMetricTable();
    };

    const updateClubPoints = async () => {
        await updatePoints();
    };


    useEffect(() => {
        checkUser();
    }, [location, checkUser]);

    return (
        <>
            <nav className='dash-nav '>

                <Link to='/' onClick={ () => setCurrentNav('') }>
                    <div className="company-logo">
                        <figure>
                            <img src={ process.env.REACT_APP_COMPANY_LOGO } alt="" />
                        </figure>
                        <div>
                            <h4>Clupik</h4>
                            <p className="p2-16">Panel de gestión</p>
                        </div>
                    </div>
                </Link>
                { user.role === 'admin' && (
                    <div className='buttons-nav'>
                        {/* <button className='my-btn mini outlined' onClick={ updatePipe }>Actualizar Pipe</button> */ }
                        <button className='my-btn mini' onClick={ updateData }>Actualizar Datos</button>
                        <button className='my-btn mini outlined' onClick={ updateClubPoints }>Act. Puntos</button>
                    </div>
                ) }

                <ClubSearch />

                {/* <ul>
                    <span className="caps2-12r menu-title">negocio</span>
                    <li className={ currentNav === 'negocio-dash' ? 'active' : null } >
                        <Link to='/negocio' onClick={ () => setCurrentNav('negocio-dash') }>
                            <MdDashboard />
                            <span className="p2-16">Dashboard</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'negocio-situacion' ? 'active' : null } >
                        <Link to='/negocio/situacion' onClick={ () => setCurrentNav('negocio-situacion') }>
                            <MdAttachMoney />
                            <span className="p2-16">Situación económica</span>
                        </Link>
                    </li>
                </ul> */}

                <ul>
                    <span className="caps2-12r menu-title">Comercial</span>
                    <li className={ currentNav === 'comercial-dashboard' ? 'active' : null } >
                        <Link to='/comercial' onClick={ () => setCurrentNav('comercial-dashboard') }>
                            <MdDashboard />
                            <span className="p2-16">Dashboard</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'comercial-clientes' ? 'active' : null } >
                        <Link to='/comercial/clientes' onClick={ () => setCurrentNav('comercial-clientes') }>
                            <MdDevices />
                            <span className="p2-16">Clientes</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'comercial-deportes' ? 'active' : null } >
                        <Link to='/comercial/deportes' onClick={ () => setCurrentNav('comercial-deportes') }>
                            <IoBasketballSharp />
                            <span className="p2-16">Deportes</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'comercial-contratos' ? 'active' : null } >
                        <Link to='/comercial/contratos' onClick={ () => setCurrentNav('comercial-contratos') }>
                            <MdAttachFile />
                            <span className="p2-16">Contratos</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'comercial-paises' ? 'active' : null } >
                        <Link to='/comercial/paises' onClick={ () => setCurrentNav('comercial-paises') }>
                            <MdFlag />
                            <span className="p2-16">Países</span>
                        </Link>
                    </li>
                </ul>
                <ul>
                    <span className="caps2-12r menu-title">Marketing</span>
                    <li className={ currentNav === 'marketin-dashboard' ? 'active' : null } >
                        <Link to='/marketing' onClick={ () => setCurrentNav('marketin-dashboard') }>
                            <MdDashboard />
                            <span className="p2-16">Dashboard</span>
                        </Link>
                    </li>
                    {/* <li className={ currentNav === 'marketing-clientes' ? 'active' : null } >
                        <Link to='/marketing/clientes' onClick={ () => setCurrentNav('marketing-clientes') }>
                            <MdDevices />
                            <span className="p2-16">Clientes</span>
                        </Link>
                    </li> */}
                </ul>

                <ul>
                    <span className="caps2-12r menu-title">Producto</span>
                    {/* <li className={ currentNav === 'producto-dash' ? 'active' : null } >
                        <Link to='/producto' onClick={ () => setCurrentNav('producto-dash') }>
                            <MdDashboard />
                            <span className="p2-16">Dashboard</span>
                        </Link>
                    </li> */}

                    <li className={ currentNav === 'producto-users' ? 'active' : null } >
                        <Link to='/producto/users' onClick={ () => setCurrentNav('producto-users') }>
                            <MdGroup />
                            <span className="p2-16">Usuarios</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'producto-estructura-deportiva' ? 'active' : null } >
                        <Link to='/producto/estructura-deportiva' onClick={ () => setCurrentNav('producto-estructura-deportiva') }>
                            <IoBasketballSharp />
                            <span className="p2-16">Estructura deportiva</span>
                        </Link>
                    </li>
                    {/* <li className={ currentNav === 'producto-inscripciones' ? 'active' : null } >
                        <Link to='/producto/inscripciones' onClick={ () => setCurrentNav('producto-inscripciones') }>
                            <MdSettings />
                            <span className="p2-16">Incripciones</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'producto-movimientos' ? 'active' : null } >
                        <Link to='/producto/movimientos' onClick={ () => setCurrentNav('producto-movimientos') }>
                            <MdSettings />
                            <span className="p2-16">Movimientos</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'producto-publicaciones' ? 'active' : null } >
                        <Link to='/producto/publicaciones' onClick={ () => setCurrentNav('producto-publicaciones') }>
                            <MdSettings />
                            <span className="p2-16">Publicaciones</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'producto-notificaciones' ? 'active' : null } >
                        <Link to='/producto/notificaciones' onClick={ () => setCurrentNav('producto-notificaciones') }>
                            <MdSettings />
                            <span className="p2-16">Notificaciones</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'producto-entrenamientos' ? 'active' : null } >
                        <Link to='/producto/entrenamientos' onClick={ () => setCurrentNav('producto-entrenamientos') }>
                            <MdSettings />
                            <span className="p2-16">Entrenamientos</span>
                        </Link>
                    </li>
                    <li className={ currentNav === 'producto-partidos' ? 'active' : null } >
                        <Link to='/producto/partidos' onClick={ () => setCurrentNav('producto-partidos') }>
                            <MdSettings />
                            <span className="p2-16">Partidos</span>
                        </Link>
                    </li> */}
                </ul>
            </nav>
        </>
    );
};

export default DashNav;
