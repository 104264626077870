import React from 'react';

import ClientsChart from '../Charts/ClientsChart/ClientsChart';
import CountryChart from '../Charts/CountryChart/CountryChart';
import SportsChart from '../Charts/SportsChart/SportsChart';
import MainTitle from '../_ui/MainTitle/MainTitle';

const DashboardComercial = () => {

    return (
        <div id="dash-comercial" className='grid'>
            <MainTitle title="Comercial" />
            <ClientsChart />
            <SportsChart />
            <CountryChart />
        </div>
    );
};

export default DashboardComercial;
