import React, { memo, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { BiCalendarAlt } from 'react-icons/bi'
import useOuterClick from '../../../hooks/useOuterClick'

const Dropdown = memo(({ list, method, currentSelection, setCurrentSelection }) => {
    const dropdown = useOuterClick(ev => closeFilter())
    const [isOpen, setIsOpen] = useState(false)


    const manageFilter = () => {
        dropdown.current.classList.toggle('open')
        setIsOpen(!isOpen)
    }

    const closeFilter = () => {
        dropdown.current.classList.remove('open')
        setIsOpen(false)

    }

    const manageList = (catId, catName) => {
        setCurrentSelection(catName)
        method(catId)
    }

    return (
        <div ref={ dropdown } onClick={ () => manageFilter() } className='dropdown '>
            <div className='caption'>
                <BiCalendarAlt /><p>{ currentSelection }</p> { isOpen ? <FaChevronUp className="arrow" /> : <FaChevronDown className="arrow" /> }
            </div>
            <div className='list'>
                { list?.map((list, idx) => (
                    <div key={ `list${idx}` } onClick={ () => manageList(list.id, list.name) } className={ currentSelection === list.name ? `item selected` : `item` }>
                        { list.name }
                    </div>
                )) }
            </div>
        </div>
    )
})

export default Dropdown
