import React from 'react';
import Acclaration from '../../_ui/Acclaration/Acclaration';
import Box from '../../_ui/Box/Box';
import MainTitle from '../../_ui/MainTitle/MainTitle';
import DeportesChart from './DeportesChart/DeportesChart';
import DeportesTable from './DeportesTable/DeportesTable';


const Deportes = () => {

    return (
        <section id="dash-comercial">
            <MainTitle title="Deportes" />
            <Box id='client-chart' title='Deportes'>
                <DeportesChart />
                <Acclaration title="Detalle por deportes" text="Información de deportes por estado de clubes" />
                <DeportesTable />
            </Box>
        </section>

    );
};

export default Deportes;
