import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../reducers/auth/AuthContext';
import { MdExpandMore, MdExpandLess, MdExitToApp, MdPersonOutline } from 'react-icons/md';
import { types } from '../../../types/types';
import { Link } from 'react-router-dom';
import useOuterClick from '../../../hooks/useOuterClick';

const DashProfile = React.memo(() => {
    const dropdown = useOuterClick(ev => setShow(false));
    const { user, dispatch } = useContext(AuthContext);
    const [firstLetter, setFirstLetter] = useState('');
    const [show, setShow] = useState(false);

    const togglePanel = () => setShow(s => !s);

    const handleLogout = async () => {
        await dispatch({ type: types.logout });
        localStorage.removeItem('clupik-token');
        localStorage.removeItem('clupik-user');
    };
    const prueba = () => {
        setShow(false);
    };

    useEffect(() => {
        setFirstLetter(f => user.name.charAt(0));
    }, [user]);

    return (
        <aside id="dash-profile" className={ show ? "opened" : null } onClick={ togglePanel } ref={ dropdown }>
            <div className="name-circle">{ firstLetter }</div>
            <h5>{ user.name }</h5>
            { !show && <MdExpandMore onClick={ togglePanel } /> }
            { show && <MdExpandLess onClick={ togglePanel } /> }
            { show &&
                <ul className="close-panel" >
                    { user.role === 'admin' &&
                        <li onClick={ prueba }>
                            <Link to="/registro"> <MdPersonOutline /> <small>Registrar usuario</small></Link>
                        </li>
                    }
                    <li onClick={ handleLogout } >
                        <MdExitToApp /> <small>Cerrar sesión</small>
                    </li>
                </ul>
            }
        </aside>
    );
});

export default DashProfile;
