import React from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';
import { downloadCSV } from '../../../helpers/csv';

const ExportCSV = ({ array, fileName }) => {
    return (
        <CgSoftwareDownload className='export-csv' onClick={ e => downloadCSV(array, fileName) } />
    );
};


export default ExportCSV;
