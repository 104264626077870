import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import PointsBadge from '../PointsBadge/PointsBadge';

const Box = ({ id, title, subtitle, children, points = false, expandable = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    return (
        <section className={ isOpen ? "box-content open" : "box-content" } id={ id }>
            <h4>
                { title }
                { points !== false && <PointsBadge points={ points } /> }
                { expandable && !isOpen && <FaChevronDown onClick={ open } className='chevron' /> }
                { expandable && isOpen && <FaChevronUp onClick={ open } className='chevron' /> }
            </h4>
            { subtitle && <p className="p4-14 box">{ subtitle }</p> }
            { children }
        </section >
    );
};

export default Box;
