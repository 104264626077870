import React, { useEffect, useState } from 'react';

const PointsBadge = ({ points = 0, compare = '/10' }) => {
    const [stateClass, setClass] = useState();

    useEffect(() => {
        if (compare !== '/60') {
            if (points >= 8) {
                setClass('a');
            } else if ((8 > points) && (5 <= points)) {
                setClass('b');
            } else if ((5 > points)) {
                setClass('c');
            }
        } else {
            if (points >= 40) {
                setClass('a');
            } else if ((40 > points) && (20 <= points)) {
                setClass('b');
            } else if ((20 > points)) {
                setClass('c');
            }
        }
    }, [points, compare]);

    return (
        <div className={ `points-badge ${stateClass}` }>
            <span>{ points ? points : 0 }{ compare }</span>
        </div>
    );
};

export default React.memo(PointsBadge);