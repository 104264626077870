import React, { useEffect, useState, useCallback } from 'react';
import Box from '../../_ui/Box/Box';

import { getClientsCount } from '../../../actions/business.action';

import Dropdown from '../../_ui/DropDown/DropDown';
import Spinner from '../../_ui/Spinner/Spinner';
import MarketingFunnel from '../MarketingFunnel/MarketingFunnel';

import { firstDay, meses } from '../../../helpers/dates';
import { formatNumber } from '../../../helpers/_helper';
import { dropList } from '../../../helpers/droplist';

import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';

const MarketingMainChart = () => {
    const [data, setData] = useState([]);
    const [clubs, setClubs] = useState(0);
    const [wonClubs, setWonClubs] = useState(0);
    const [trialClubs, setTrialClubs] = useState(0);
    const [noState, setNoState] = useState(0);
    const [loading, setLoading] = useState(false);
    const getData = async (qt) => {
        const data = await getClientsCount(firstDay(qt), qt);
        setClubs(data.total);
        const noStateGraph = {
            id: 'Oportunidades',
            data: [],
        };
        const trialGraph = {
            id: 'Demo',
            data: [],
        };
        const wonGraph = {
            id: 'Clientes',
            data: [],
        };
        const won = data.won;
        let wonAdd = won?.length > 0 ? won.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setWonClubs(wonAdd?.count);
        won?.forEach((ad) => {
            wonGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
        });
        setData((data) => [...data, wonGraph]);

        const trial = data.trial;
        let trialAdd = trial?.length > 0 ? trial.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setTrialClubs(trialAdd?.count);
        trial?.forEach((ad) => {
            trialGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
        });
        setData((data) => [...data, trialGraph]);

        const withNoState = data.created;
        let noStateAdd = withNoState?.length > 0 ? withNoState.reduce((a, b) => ({ count: a.count + b.count })) : 0;
        setNoState(noStateAdd?.count);
        withNoState?.forEach((ad) => {
            noStateGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
        });
        setData((data) => [...data, noStateGraph]);
        setLoading(false);
    };

    const firstData = useCallback(() => {
        setLoading(true);
        getData(6);
    }, []);

    useEffect(() => {
        setCurrentSelection('Últimos 6 meses');
        setData([]);
        firstData();
    }, [firstData]);

    const selectData = (id) => {
        setLoading(true);
        setClubs(0);
        setWonClubs(0);
        setTrialClubs(0);
        setNoState(0);
        setData([]);
        getData(id);
    };

    const [currentSelection, setCurrentSelection] = useState('Últimos 6 meses');

    return (
        <Box id='client-chart' title='Captación de clientes'>
            <Dropdown list={ dropList } method={ selectData } currentSelection={ currentSelection } setCurrentSelection={ setCurrentSelection } />
            {
                loading ? (
                    <Spinner />
                ) : (
                    <div className='fix'>
                        <article className='left'>
                            <ResponsiveLine
                                data={ data }
                                margin={ { top: 5, right: 10, bottom: 100, left: 30 } }
                                xScale={ { type: 'point' } }
                                yScale={ { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false } }
                                yFormat='>-0,.2~f'
                                axisTop={ null }
                                axisRight={ null }
                                axisBottom={ {
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 10,
                                    tickRotation: -90,
                                    // legend: 'Mes',
                                    legendOffset: 0,
                                    legendPosition: 'middle',
                                } }
                                axisLeft={ {
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    // legend: 'Clubs añadidos',
                                    legendOffset: -40,
                                    legendPosition: 'middle',
                                } }
                                colors={ { scheme: 'paired' } }
                                enableGridX={ false }
                                pointSize={ 10 }
                                pointColor={ { theme: 'background' } }
                                pointBorderWidth={ 2 }
                                pointBorderColor={ { from: 'serieColor' } }
                                pointLabelYOffset={ -12 }
                                useMesh={ true }
                                enableSlices='x'
                                legends={ [
                                    {
                                        anchor: 'bottom-left',
                                        direction: 'row',
                                        justify: false,
                                        translateX: -16,
                                        translateY: 90,
                                        itemWidth: 91,
                                        itemHeight: 10,
                                        itemsSpacing: 4,
                                        symbolSize: 10,
                                        symbolShape: 'circle',
                                        itemDirection: 'left-to-right',
                                        itemTextColor: '#777',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemOpacity: 1,
                                                },
                                            },
                                        ],
                                    },
                                ] }
                            />
                        </article>
                        <article className='right'>
                            <div className='data'>
                                <div className='addition'>
                                    <h1>{ formatNumber(wonClubs) }</h1>
                                    <div>
                                        <small className='p2-16'>Clientes</small>
                                        {/* <small className='p2-16'>Activos // Clientes</small> */ }
                                    </div>
                                </div>
                                <div className='addition'>
                                    <h2>{ formatNumber(trialClubs) }</h2>
                                    <div>
                                        <small className='p2-16'>Demos</small>
                                    </div>
                                </div>
                                <div className='addition'>
                                    <h2>{ formatNumber(noState) }</h2>
                                    <div>
                                        <small className='p2-16'>Oportunidades</small>
                                        {/* <small className='p2-16'>Sin Estado // Oportunidades</small> */ }
                                    </div>
                                </div>
                                <div className='addition'>
                                    <h2>{ formatNumber(clubs) }</h2>
                                    <div>
                                        <small className='p2-16'>Leads</small>
                                        {/* <small className='p2-16'>Creados//Leads</small> */ }
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div >
                )
            }
            <MarketingFunnel leads={ clubs } oport={ noState } demos={ trialClubs } clients={ wonClubs } loading={ loading } />
        </Box>
    );
};

export default MarketingMainChart;
