import dayjs from 'dayjs';
import { fetchConToken, fetchConTokenClupik } from '../helpers/fetch';

export const getTeamsAndRolesBySeason = async (season, state) => {
	let url;
	if (state) {
		url = `sport-structure/teams?season=${season}&state=${state}`;
	} else {
		url = `sport-structure/teams?season=${season}`;
	}
	const resp = await fetchConToken(url, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body;
	} else {
		const errorMsg = body.msg;
		throw new Error(errorMsg);
	}
};
export const getTeamStructure = async (season, state) => {
	let url;
	if (state) {
		url = `sport-structure?season=${season}&state=${state}`;
	} else {
		url = `sport-structure?season=${season}`;
	}
	const resp = await fetchConToken(url, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.teamsWithRoles;
	} else {
		const errorMsg = body.msg;
		throw new Error(errorMsg);
	}
};

export const getSeasons = async () => {
	try {
		const resp = await fetchConTokenClupik('seasons');
		const body = await resp.json();
		body.forEach((season) => {
			season.name = season.title;
			delete season.start;
			delete season.end;
			delete season.year;
			delete season.title;
		});
		return body;
	} catch (error) {
		throw new Error(error);
	}
};
export const getCurrentSeason = async (type) => {
	try {
		const resp = await fetchConTokenClupik('seasons');
		let seasons = await resp.json();
		seasons = seasons.filter((elm) => elm.seasonType === type);
		const nowDate = dayjs();
		let currentSeason = null;
		let index = 0;
		let found = false;
		let startDate;
		let endDate;
		while (index < seasons.length && !found) {
			startDate = dayjs(seasons[index].start);
			endDate = dayjs(seasons[index].end);
			found = nowDate >= startDate && nowDate < endDate;
			if (!found) index++;
		}

		if (found) currentSeason = seasons[index];

		return currentSeason;
	} catch (error) {
		return false;
	}
};
