import React, { useCallback, useEffect, useState } from 'react';

import { getProductUsers, usersByRole } from '../../../actions/product.action';

import Acclaration from '../../_ui/Acclaration/Acclaration';
import Spinner from '../../_ui/Spinner/Spinner';
import Dropdown from '../../_ui/DropDown/DropDown';
import Box from '../../_ui/Box/Box';
import UsersRolTable from '../../Product/ProductDataBase/UsersRolTable/UsersRolTable';

import { dropList, dropListClubs } from '../../../helpers/droplist';
import { formatNumber } from '../../../helpers/_helper';

import { ResponsiveLine } from '@nivo/line';

const ProductUsersChart = () => {
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [numbers, setNumbers] = useState({ active: 0, pending: 0, locked: 0, dataBase: 0, total: 0 });
    const [pendingGraph, setPendingGraph] = useState(true);
    const [pendingTable, setPendingTable] = useState(true);
    const [clubState, setClubState] = useState({ id: 4, name: 'Clubs Activos' });

    const [amount, setAmount] = useState({ id: 6, name: "Últimos 6 meses" });

    const getData = async (qt, state) => {
        let dataFromEndpoint = await getProductUsers(qt, state);
        //Locked
        let locked = dataFromEndpoint.locked;
        let lockedGraph = {
            id: "Bloqueados",
            data: dataFromEndpoint.locked.reverse()
        };
        setNumbers(n => ({ ...n, locked: formatNumber(locked.reduce((a, b) => a + b.y, 0)) }));
        setData(data => [...data, lockedGraph]);
        //noemail
        let noemail = dataFromEndpoint.noemail;
        let noemailGraph = {
            id: "BBDD",
            data: dataFromEndpoint.noemail.reverse()
        };
        setNumbers(n => ({ ...n, dataBase: formatNumber(noemail.reduce((a, b) => a + b.y, 0)) }));
        setData(data => [...data, noemailGraph]);
        //pending
        let pending = dataFromEndpoint.pending;
        let pendingGraph = {
            id: "Pendientes",
            data: dataFromEndpoint.pending.reverse()
        };
        setNumbers(n => ({ ...n, pending: formatNumber(pending.reduce((a, b) => a + b.y, 0)) }));
        setData(data => [...data, pendingGraph]);
        //active
        let active = dataFromEndpoint.active;
        let activeGraph = {
            id: "Activos",
            data: dataFromEndpoint.active.reverse()
        };
        setNumbers(n => ({ ...n, active: formatNumber(active.reduce((a, b) => a + b.y, 0)) }));
        setData(data => [...data, activeGraph]);

        setPendingGraph(false);
    };

    const getRoles = async (qt, state) => {
        const getRoles = await usersByRole(qt, state);
        setTableData([getRoles.admin, getRoles.editor, getRoles.accountant, getRoles.coordinator, getRoles.coaches, getRoles.players, getRoles.tutor]);
        setTableData(data => data.sort((a, b) => b.total - a.total));
        setPendingTable(false);
    };

    const firstData = useCallback(() => {
        setPendingTable(true);
        setPendingGraph(true);
        getData(6, clubState.id);
        getRoles(6, clubState.id);
    }, [clubState.id]);

    useEffect(() => {
        setData([]);
        setNumbers({ active: 0, pending: 0, locked: 0, dataBase: 0, total: 0 });
        firstData();
    }, [firstData]);

    const selectClubState = (id) => {
        setPendingTable(true);
        setPendingGraph(true);
        setData([]);
        setTableData([]);
        setClubState(c => ({ ...c, id: id }));
        setNumbers({ active: 0, pending: 0, locked: 0, dataBase: 0, total: 0 });
        getData(amount.id, id);
        getRoles(amount.id, id);
    };

    const selectClubStateName = (name) => {
        setClubState(c => ({ ...c, name: name }));
    };

    const selectAmountTime = (id) => {
        setPendingTable(true);
        setPendingGraph(true);
        setData([]);
        setTableData([]);
        setAmount(a => ({ ...a, id: id }));
        setNumbers({ active: 0, pending: 0, locked: 0, dataBase: 0, total: 0 });
        getData(id, clubState.id);
        getRoles(id, clubState.id);
    };
    const selectAmountTimeName = (name) => {
        setAmount(a => ({ ...a, name: name }));
    };

    return (
        <Box id="client-chart" title="Usuarios">
            <div className="drops">
                <Dropdown list={ dropListClubs } method={ selectClubState } currentSelection={ clubState.name } setCurrentSelection={ selectClubStateName } />
                <Dropdown list={ dropList } method={ selectAmountTime } currentSelection={ amount.name } setCurrentSelection={ selectAmountTimeName } />
            </div>
            { pendingGraph ? (
                <Spinner />
            ) : (

                <div className="fix">
                    <article className="left">
                        <ResponsiveLine
                            data={ data }
                            margin={ { top: 5, right: 10, bottom: 100, left: 30 } }
                            xScale={ { type: 'point' } }
                            yScale={ { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false } }
                            valueScale={ { type: "symlog" } }
                            yFormat=">-0,.2~f"
                            axisTop={ null }
                            enableSlices="x"
                            axisRight={ null }
                            axisBottom={ {
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 10,
                                tickRotation: -90,
                                // legend: 'Mes',
                                legendOffset: 0,
                                legendPosition: 'middle'
                            } }
                            axisLeft={ {
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                // legend: 'Clubs añadidos',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            } }
                            colors={ { scheme: 'set3' } }
                            enableGridX={ false }
                            pointSize={ 10 }
                            pointColor={ { theme: 'background' } }
                            pointBorderWidth={ 2 }
                            pointBorderColor={ { from: 'serieColor' } }
                            pointLabelYOffset={ -12 }
                            useMesh={ true }
                            legends={ [
                                {
                                    anchor: 'bottom-left',
                                    direction: 'row',
                                    justify: false,
                                    translateX: -16,
                                    translateY: 90,
                                    itemWidth: 91,
                                    itemHeight: 10,
                                    itemsSpacing: 4,
                                    symbolSize: 10,
                                    symbolShape: 'circle',
                                    itemDirection: 'left-to-right',
                                    itemTextColor: '#777',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ] }
                        />
                    </article>
                    <article className="right">
                        <div className="data">
                            <div className="addition">
                                <h1>{ numbers.active }</h1>
                                <div>
                                    <small className="p2-16">Activos</small>
                                </div>
                            </div>
                            <div className="addition">
                                <h3>{ numbers.pending }</h3>
                                <div>
                                    <small className="p2-16">Pendientes</small>
                                </div>
                            </div>
                            <div className="addition">
                                <h3>{ numbers.dataBase }</h3>
                                <div>
                                    <small className="p2-16">Base de datos</small>
                                </div>
                            </div>
                            <div className="addition">
                                <h3>{ numbers.locked }</h3>
                                <div>
                                    <small className="p2-16">Bloqueados</small>
                                </div>
                            </div>
                        </div>

                    </article>
                </div>
            ) }
            <Acclaration title="Detalle por roles" text="Información de usuarios por roles en la aplicación" />
            <UsersRolTable tableData={ tableData } pending={ pendingTable } />
        </Box>
    );
};

export default ProductUsersChart;
