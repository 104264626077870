import React, { useContext } from 'react';
import { startLogin } from '../../../actions/auth.action';
import { AuthContext } from '../../../reducers/auth/AuthContext';
import useForm from '../../../hooks/useForm';
import { Link } from 'react-router-dom';
import { types } from '../../../types/types';

const LoginScreen = () => {
	const { values, handleInputChange } = useForm({});
	const { email, password } = values;
	const { dispatch } = useContext(AuthContext);
	const handleLogin = async (e) => {
		e.preventDefault();
		const loggedUser = await startLogin(email, password);

		dispatch({ type: types.login, payload: loggedUser });
	};
	return (
		<>
			<section className='login'>
				<figure>
					<img src={ process.env.REACT_APP_COMPANY_LOGO_BIG } alt="" />
				</figure>

				<form onSubmit={ handleLogin }>
					<label>Email</label>
					<input type='email' onChange={ handleInputChange } placeholder='Tu email' name='email' />
					<label>Contraseña</label>
					<input type='password' onChange={ handleInputChange } placeholder='Contraseña' name='password' />
					<button type='submit' className='my-btn mini'>
						Entrar
					</button>
				</form>
				<small>
					<Link to='/password'>Recuperar contraseña</Link>
				</small>
			</section>
		</>
	);
};

export default LoginScreen;
