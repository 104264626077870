import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../../_ui/Box/Box';
import { ResponsivePie } from '@nivo/pie';

const ClubPayments = ({ holded }) => {
    const isMounted = useRef(false);
    const [graph, setGraph] = useState([]);
    const [data, setData] = useState({ last: '-€', next: '-€', paid: '0€', unpaid: '0€' });
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const manageData = () => {
            let nonpaid = 0;
            holded?.forEach(elm => (nonpaid += elm.paymentsPending));
            setData(d => ({ ...d, unpaid: nonpaid.toFixed(2) + '€' }));
            let paid = 0;
            holded?.forEach(elm => (paid += elm.paymentsTotal));
            setData(d => ({ ...d, paid: paid.toFixed(2) + '€' }));
            let last = holded[0].total;
            setData(d => ({ ...d, last: last.toFixed(2) + '€' }));

            setGraph([{
                id: 'Pagado', label: 'Pagado', value: paid, color: "#12a23a"
            }, {
                id: 'Pendiente', label: 'Pendiente', value: nonpaid, color: "#d63734"
            }]);
        };
        if (isMounted.current) {
            if (holded?.length > 0) {
                manageData();
            }
        }
    }, []);


    return (
        <Box title="Resumen de pagos" id="club-payments">
            <div className="content">

                <div className="top">
                    <div className="data">
                        <h2>{ data.last }</h2>
                        <small className="p2-16">Importe última factura</small>
                    </div>
                </div>

                <div className="bottom">
                    <div className="left">
                        <p><span className='color green'></span><strong>Pagado: </strong>{ data.paid }</p>
                        <p><span className='color red'></span><strong>Pendiente: </strong>{ data.unpaid }</p>
                    </div>
                    <ResponsivePie
                        data={ graph }
                        colors={ ['#12a23a', '#d63734'] }
                        colorBy="index"
                        margin={ { top: 24, right: 0, bottom: 0, left: 24 } }
                        innerRadius={ 0.5 }
                        activeOuterRadiusOffset={ 0 }
                        borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                        enableArcLinkLabels={ false }
                        arcLinkLabelsSkipAngle={ 10 }
                        enableArcLabels={ false }
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={ 2 }
                        arcLinkLabelsColor={ { from: 'color' } }
                        arcLabelsSkipAngle={ 10 }
                        arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                    />
                </div>
            </div>

        </Box>
    );
};

export default ClubPayments;