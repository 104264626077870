export const getClubState = (state) => {
	state = Number(state);
	switch (state) {
		case 1:
			return 'Club en Baja';
		case 2:
			return 'Club en BC';
		case 3:
			return 'Club en BNC';
		case 4:
			return 'Club Activo';
		case 5:
			return 'Club en trial';
		case 6:
			return 'Club Creado';
		default:
			break;
	}
};
export const getContractState = (state) => {
	state = Number(state);
	switch (state) {
		case 1:
			return 'Clupik10';
		case 2:
			return 'Clupik20';
		case 3:
			return 'Clupik+';
		case 0:
			return 'Free';
		default:
			break;
	}
};

export const formatNumber = (number) => {
	return new Intl.NumberFormat('de-DE').format(number);
};

export const getCountry = (country) => {
	let regionNames = new Intl.DisplayNames(['es'], { type: 'region' });
	return regionNames.of(country);
};
