import React from 'react';
import MainTitle from '../../_ui/MainTitle/MainTitle';
import ContratoChart from './ContratoChart';

const Contratos = () => {
    return (
        <>
            <div id="dash-comercial">
                <MainTitle title="Contratos" />
                <ContratoChart />
            </div>

        </>
    );
};

export default Contratos;