import React, { useEffect, useRef, useState } from 'react';
import { admonByClub, usersByRoleByClub } from '../../../../../actions/club.action';
import { formatNumber } from '../../../../../helpers/_helper';
import UsersRolTable from '../../../../Product/ProductDataBase/UsersRolTable/UsersRolTable';
import Box from '../../../../_ui/Box/Box';
import LineGraph from '../../../../_ui/LineGraph/LineGraph';
import { ResponsivePie } from '@nivo/pie';
import { getPaymentType } from '../../../../../helpers/random';
import Spinner from '../../../../_ui/Spinner/Spinner';
import Alert from '../../../../_ui/Alert/Alert';

const ClubAdmon = ({ points, clubId, season, coin, module }) => {
    const isMounted = useRef(false);
    const [tableData, setTableData] = useState([]);
    const [pendingTable, setPendingTable] = useState(true);
    const [graph, setGraph] = useState({ actives: 0, pending: 0 });
    const [admon, setAdmon] = useState(null);
    const [totalMoney, setTotalMoney] = useState(0);
    const [loading, setLoading] = useState(false);

    const [gmv, setGmv] = useState([]);
    const [inscriptions, setInscriptions] = useState([]);

    const countUsers = (info) => {
        info.forEach(elm => {
            setGraph(p => ({ ...p, actives: Number(p.actives) + Number(elm.active), pending: Number(p.pending) + Number(elm.pending) }));
        });
    };

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const getAdmon = async () => {
            const data = await admonByClub(clubId, season.id);
            if (data) {
                delete data.ok;
                setTotalMoney(data.transactions?.length > 0 ? data.transactions.reduce((a, b) => a + (Number(b.money) / 100), 0) : 0);
                setAdmon(data);
                let aux = [];
                data.transactions?.forEach(elm => {
                    aux.push({ id: getPaymentType(elm.payment_method), label: getPaymentType(elm.payment_method) + ': ' + formatNumber(Number(elm.money) / 100) + coin, value: Number(elm.money) / 100 });
                });
                setGmv(aux);
                setInscriptions([
                    { id: 'Rechazadas', label: 'Rechazadas: ' + data.requests?.rejected, value: data.requests?.rejected },
                    { id: 'Pendientes', label: 'Pendientes: ' + data.requests?.pending, value: data.requests?.pending },
                    { id: 'Validadas', label: 'Validadas: ' + data.requests?.validated, value: data.requests?.validated },
                ]);
                setLoading(false);
            }
        };
        const getRoles = async () => {
            const data = await usersByRoleByClub(clubId);
            countUsers([data.admin, data.editor, data.accountant, data.coordinator, data.coaches, data.players, data.tutor]);
            setTableData([data.admin, data.editor, data.accountant, data.coordinator, data.coaches, data.players, data.tutor]);
            setTableData(data => data.sort((a, b) => b.total - a.total));
            setPendingTable(false);
        };
        if (isMounted.current) {
            getRoles();
            getAdmon();
        }
    }, []);


    return (
        <Box subtitle={ 'Últimos 30 días' } expandable={ module } title="Administración" id="club-admon" points={ points }>
            {
                module ? (
                    loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className="preview">
                                <div className="each">
                                    <h6 className="h6-14-b">GMV</h6>
                                    { admon && <p className='p4-14'>{ 'Total: ' + formatNumber(totalMoney) + coin } { admon?.transactions?.some(elm => elm.payment_method === 'STR') && <span>{ ' - Clupik Pay:  ' + formatNumber(Number(admon?.transactions.find(elm => elm.payment_method === 'STR').money) / 100) + coin }</span> } </p> }
                                </div >
                                <div className="each">
                                    <h6 className="h6-14-b">Inscripciones</h6>
                                    { admon && <p className='p4-14'>{ admon?.forms && <span>{ admon?.forms.forms + ' formularios' }</span> } { admon?.requests && <span>{ ' - ' + admon?.requests.total + ' recibidas' }</span> }</p> }
                                </div>
                                <div className="each">
                                    <h6 className="h6-14-b">Usuarios</h6>
                                    { graph && <p className='p4-14'>{ 'Total: ' + (graph.actives + graph.pending) + ' - Activos: ' + graph.actives + ' (' + Math.round((graph.actives / (graph.actives + graph.pending)) * 100) + '%)' }</p> }
                                </div>
                            </div >
                            <div className="view">
                                <div className="graphs">

                                    <div className="left">
                                        <h5 className='h5-16-b'>GMV</h5>
                                        <p className='p2-16'>{ season?.title }</p>
                                        <ResponsivePie
                                            data={ gmv }
                                            colors={ ['#F98600', '#00BA34', '#FBEC66', '#FBB666', '#0085FF'] }
                                            margin={ { top: 0, right: 0, bottom: 0, left: 0 } }
                                            innerRadius={ 0.5 }
                                            activeOuterRadiusOffset={ 0 }
                                            borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                                            enableArcLabels={ false }
                                            enableArcLinkLabels={ false }
                                            arcLinkLabelsSkipAngle={ 10 }
                                            arcLinkLabelsTextColor="#333333"
                                            arcLinkLabelsThickness={ 2 }
                                            arcLinkLabelsColor={ { from: 'color' } }
                                            arcLabelsSkipAngle={ 10 }
                                            arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                                            legends={ [
                                                {
                                                    anchor: 'left',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemWidth: 400,
                                                    itemHeight: 29,
                                                    itemTextColor: '#1c1c1c',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 12,
                                                    symbolShape: 'square',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ] }
                                        />
                                    </div>
                                    <div className="right">
                                        <h5 className='h5-16-b'>Inscripciones</h5>
                                        <p className='p2-16'>{ season?.title }</p>

                                        <ResponsivePie
                                            data={ inscriptions }
                                            colors={ ['#E92C2C', '#FBEC66', '#00BA34'] }
                                            colorBy="index"
                                            margin={ { top: 0, right: 0, bottom: 0, left: 0 } }
                                            innerRadius={ 0.5 }
                                            enableArcLabels={ false }
                                            activeOuterRadiusOffset={ 0 }
                                            borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                                            enableArcLinkLabels={ false }
                                            arcLinkLabelsSkipAngle={ 10 }
                                            arcLinkLabelsTextColor="#333333"
                                            arcLinkLabelsThickness={ 2 }
                                            arcLinkLabelsColor={ { from: 'color' } }
                                            arcLabelsSkipAngle={ 10 }
                                            arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                                            legends={ [
                                                {
                                                    anchor: 'left',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemWidth: 400,
                                                    itemHeight: 29,
                                                    itemTextColor: '#1c1c1c',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 12,
                                                    symbolShape: 'square',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ] }

                                        />
                                    </div>
                                </div>
                                <LineGraph graph={ graph } pending={ pendingTable } />
                                <UsersRolTable tableData={ tableData } pending={ pendingTable } />
                            </div>
                        </>
                    )

                ) : (
                    <Alert text='Sin resultados. Este club no tiene activado el módulo de contabilidad.' />
                )
            }
        </Box>
    );
};

export default React.memo(ClubAdmon);