import { fetchConToken } from '../helpers/fetch';

export const sectionsCount = async () => {
	const resp = await fetchConToken('sports/sections/count', 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.sectionCount;
	} else {
		const errorMsg = body.msg;
		console.log(errorMsg);
		return false;
	}
};

export const sportsCount = async () => {
	const resp = await fetchConToken('sports/sections', 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.sportsCount;
	} else {
		const errorMsg = body.msg;
		console.log(errorMsg);
		return false;
	}
};

export const getSportsGraph = async (qt, state) => {
	let url;
	if (state) {
		url = `sports/graph?amount=${qt}&state=${state}`;
	} else {
		url = `sports/graph?amount=${qt}`;
	}
	try {
		const resp = await fetchConToken(url, 'GET');
		const body = await resp.json();

		if (body.ok) {
			return body.result;
		} else {
			const errorMsg = body.err;
			console.log(errorMsg);
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};
