import React from 'react';

const MainTitle = ({ title, subtitle, isClub = false, clubId, points = 0, clubUrl }) => {
    return (

        isClub ? (
            <section id="main-title-club" >
                <figure className="avatar">
                    <img src={ `https://api.clupik.com/clubs/${clubId}/images/navbar.png` } alt="" />
                </figure>
                <a className="h2" href={ clubUrl } rel="noreferrer" target="_blank">{ title }</a>
                <p className="points">{ points ? points : 0 }</p>
            </section>
        ) : (
            <section id="main-title">
                <h2>{ title }</h2>
                { subtitle && <p className="p1-20">{ subtitle }</p> }
            </section>
        )

    );
};

export default MainTitle;
