import dayjs from 'dayjs';
import { fetchConToken } from '../helpers/fetch';

export const clubCount = async () => {
	const resp = await fetchConToken('general-data/club-count', 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.clubesCount[0].count;
	} else {
		const errorMsg = body.err;
		console.log(errorMsg);
	}
};
export const clubCountByDate = async (dates) => {
	if (dates.dates) {
		const resp = await fetchConToken(`general-data/club-count/dates?start=${dates.dates.start}&end=${dates.dates.end}`, 'GET');
		const body = await resp.json();
		if (body.ok) {
			return body.clubesCount;
		} else {
			const errorMsg = body.err;
			console.log(errorMsg);
		}
	} else if (dates.monthly) {
		const resp = await fetchConToken(`general-data/club-count/dates?month=${dates.monthly.month}&year=${dates.monthly.year}`, 'GET');
		const body = await resp.json();
		if (body.ok) {
			return body.clubesCount[0].count;
		} else {
			const errorMsg = body.err;
			console.log(errorMsg);
		}
	}
};
export const clubCountByMonth = async (month, year) => {
	if (!month) {
		month = dayjs().subtract(1, 'months').get('month');
		year = dayjs().get('year');
	}
	const resp = await fetchConToken(`general-data/club-count/month?month=${month}&year=${year}`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.monthCount;
	} else {
		const errorMsg = body.err;
		console.log(errorMsg);
	}
};

export const clubCountByCountry = async () => {
	const resp = await fetchConToken('general-data/country', 'GET');
	const body = await resp.json();
	if (body.ok) {
		// let result = body.result.map((elm) => {
		// 	if (elm.total !== '0') {
		// 		return { country: elm.countryName, count: Number(elm.total) };
		// 	}
		// });
		let result = body.result.map((elm) => elm.total !== '0' && { country: elm.countryName, count: Number(elm.total) });
		result = result.filter((elm) => elm);
		return result;
	} else {
		const errorMsg = body.err;
		console.log(errorMsg);
	}
};
