import React from 'react'

const Acclaration = ({ title, text }) => {
    return (
        <article className="acclaration">
            <h4>{ title }</h4>
            <p className="p2-16">
                { text }
            </p>
        </article>
    )
}

export default Acclaration
