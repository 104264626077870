import React, { useRef, useState, useEffect } from 'react';
import { GrClose } from 'react-icons/gr';
import { GoSearch } from 'react-icons/go';
import useForm from '../../../hooks/useForm';
import Spinner from '../Spinner/Spinner';
import { searchClub } from '../../../actions/club.action';
import { Link } from 'react-router-dom';
import useOuterClick from '../../../hooks/useOuterClick';

const ClubSearch = () => {
    const dropdown = useOuterClick(ev => clear());
    const searchInput = useRef();
    const [loading, setLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const { values, handleInputChange } = useForm();

    const handleSubmit = (e) => {
        setIsSearching(true);
        setLoading(true);
        e.preventDefault();
        if (values.search) {
            search(values.search);
        }
    };
    const handleKeySubmit = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    const clear = () => {
        setNothing(false);
        setIsSearching(false);
        setLoading(false);
        searchInput.current.value = '';
    };

    useEffect(() => {
        if (values.search?.length < 3) {
            setNothing(false);
            setIsSearching(false);
            setLoading(false);
        } else if (values.search?.length > 3) {
            setIsSearching(true);
            setLoading(true);
            search(values.search);
        }
    }, [values]);

    const [nothing, setNothing] = useState(false);
    const [clubs, setClubs] = useState([]);

    const search = async (value) => {
        const data = await searchClub(value);
        if (data.length > 0) {
            setClubs(data);
        } else {
            setNothing(true);
        }
        setLoading(false);
    };

    return (
        <>
            <div ref={ dropdown } className="search-bar">
                <input autoComplete="off" ref={ searchInput } name="search" onChange={ handleInputChange } className="search-input" placeholder='Búsqueda por club' onKeyDown={ handleKeySubmit } />
                {
                    isSearching ? (
                        <GrClose className="icon" onClick={ () => clear() } />
                    ) : (
                        <GoSearch className="icon" onClick={ handleSubmit } />
                    )
                }
                <div className={ isSearching ? `search-results open` : `search-results closed-bar` }>
                    {
                        loading ? (
                            <Spinner message={ 'Buscando...' } />
                        ) : (
                            nothing ? (
                                <p className='caps3-10r'>No hay clubs que coincidan con tu búsqueda.</p>
                            ) : (
                                clubs?.map((club) => (
                                    <Link to={ `/comercial/clientes/club/${club.id}` } key={ `${club.id}` } className="club" onClick={ clear }>
                                        <figure className="avatar">
                                            <img src={ `https://api.clupik.com/clubs/${club.id}/images/navbar.png` } alt="" />
                                        </figure>
                                        <p className='caps2-12sb'>{ club.name }</p>
                                    </Link>
                                ))
                            )
                        )
                    }
                </div>
            </div>

        </>
    );
};

export default ClubSearch;