import React, { useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import Box from '../../../../_ui/Box/Box';
import ListElm from '../../../../_ui/ListElm/ListElm';
import StateBadge from '../../../../_ui/StateBadge/StateBadge';
import ModalInfo from './ModalInfo/ModalInfo';
import { FaUserCircle, FaPhoneAlt, FaEnvelope, FaHouseUser, FaFileContract, FaMobileAlt, FaExternalLinkAlt } from 'react-icons/fa';
import { getApp, getContract } from '../../../../../helpers/random';

const ClubInfo = ({ deal, organization, state }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <Box title={ 'Contacto' } id="contact-info">
            <StateBadge row={ { state: state } } />
            <div className="content">
                <div className="left">
                    <ListElm title="Persona de contacto:" content={ deal?.person_id.name } icon={ <FaUserCircle /> } />
                    <ListElm title="Teléfono:" content={ deal?.person_id.phone[0].value } icon={ <FaPhoneAlt /> } />
                    <ListElm title="Correo electrónico:" content={ deal?.person_id.email[0].value } icon={ <FaEnvelope /> } />
                    <ListElm title="Dirección:" content={ deal?.org_id.address ? deal?.org_id.address : '-' } icon={ <FaHouseUser /> } />
                </div>
                <div className="right">
                    <ListElm title="Tipo de contrato:" content={ deal && getContract(deal["0268513c0f313afe84347c36747a7ec881b3192a"]) } icon={ <FaFileContract /> } />
                    <ListElm title="Tipo de app:" content={ deal && getApp(deal["89f06122bc10f606bb278b772f9326b9c314475e"]) } icon={ <FaMobileAlt /> } />
                    <ListElm title="Link Pipe:" content={ 'Ver en Pipe' } isLink link={ `https://clupik.pipedrive.com/deal/${deal.id}` } icon={ <FaExternalLinkAlt /> } />
                </div>
            </div>
            <button className='client-list-link' onClick={ handleShow }>Más información <MdChevronRight /></button>
            { show && <ModalInfo handleClose={ handleClose } show={ show } deal={ deal } organization={ organization } state={ state } /> }
        </Box>
    );
};

export default ClubInfo;