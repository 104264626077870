import React, { useEffect, useState } from 'react';

const StateBadge = ({ row }) => {
	const [clubState, setState] = useState('Sin Estado');
	const [stateClass, setClass] = useState('se');
	useEffect(() => {
		switch (Number(row.state)) {
			case 1:
				setState('Baja');
				setClass('baja');
				break;
			case 2:
				setState('Baja C');
				setClass('bajac');
				break;
			case 3:
				setState('Baja NC');
				setClass('bajanc');
				break;
			case 4:
				setState('Activo');
				setClass('activo');
				break;
			case 5:
				setState('Trial');
				setClass('trial');
				break;
			case 6:
				setState('S/E');
				setClass('se');
				break;
			default:
				break;
		}
	}, [row.state]);

	return (
		<div className={ `state-badge ${stateClass}` }>
			<span>{ clubState }</span>
		</div>
	);
};
export default StateBadge;
