import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import Dropdown from '../../../_ui/DropDown/DropDown';
import { dropList, dropListClubs } from '../../../../helpers/droplist';
import { getSportsGraph } from '../../../../actions/sports.action';
import Spinner from '../../../_ui/Spinner/Spinner';

const DeportesChart = () => {
    const [data, setData] = useState([]);
    const [clubState, setClubState] = useState({ id: 4, name: 'Clubs Activos' });
    const [pendingGraph, setPendingGraph] = useState(false);
    const [amount, setAmount] = useState({ id: 6, name: "Últimos 6 meses" });

    const getGraph = async (qt, state) => {
        const getData = await getSportsGraph(qt, state);
        getData.length > 0 && getData.forEach(elm => {
            setData(data => [...data, {
                id: elm.sport,
                data: elm.data
            }]);
        });
        setPendingGraph(false);
    };

    const selectClubState = (id) => {
        setPendingGraph(true);
        setData([]);
        setClubState(c => ({ ...c, id: id }));
        getGraph(amount.id, id);
    };
    const selectClubStateName = (name) => {
        setClubState(c => ({ ...c, name: name }));
    };

    const selectAmountTime = (id) => {
        setPendingGraph(true);
        setData([]);
        setAmount(a => ({ ...a, id: id }));
        getGraph(id, clubState.id);
    };

    const selectAmountTimeName = (name) => {
        setAmount(a => ({ ...a, name: name }));
    };

    useEffect(() => {
        setPendingGraph(true);
        getGraph(6, 4);

    }, []);


    return (
        <>
            <div className="drops">
                <Dropdown list={ dropListClubs } method={ selectClubState } currentSelection={ clubState.name } setCurrentSelection={ selectClubStateName } />
                <Dropdown list={ dropList } method={ selectAmountTime } currentSelection={ amount.name } setCurrentSelection={ selectAmountTimeName } />
            </div>

            { pendingGraph ? (
                <Spinner />
            ) : (
                <div className="fix">
                    <div className="left">

                        <ResponsiveLine
                            data={ data }
                            margin={ { top: 5, right: 10, bottom: 100, left: 30 } }
                            xScale={ { type: 'point' } }
                            yScale={ { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false } }
                            valueScale={ { type: "symlog" } }
                            yFormat=">-0,.2~f"
                            axisTop={ null }
                            enableSlices="x"
                            axisRight={ null }
                            axisBottom={ {
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 10,
                                tickRotation: -90,
                                // legend: 'Mes',
                                legendOffset: 0,
                                legendPosition: 'middle'
                            } }
                            axisLeft={ {
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                // legend: 'Clubs añadidos',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            } }
                            colors={ { scheme: 'set3' } }
                            enableGridX={ false }
                            pointSize={ 10 }
                            pointColor={ { theme: 'background' } }
                            pointBorderWidth={ 2 }
                            pointBorderColor={ { from: 'serieColor' } }
                            pointLabelYOffset={ -12 }
                            useMesh={ true }
                            legends={ [
                                {
                                    anchor: 'bottom-left',
                                    direction: 'row',
                                    justify: false,
                                    translateX: -16,
                                    translateY: 90,
                                    itemWidth: 91,
                                    itemHeight: 10,
                                    itemsSpacing: 4,
                                    symbolSize: 10,
                                    symbolShape: 'circle',
                                    itemDirection: 'left-to-right',
                                    itemTextColor: '#777',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ] }
                        />
                    </div>
                </div>


            ) }


        </>
    );
};

export default DeportesChart;
