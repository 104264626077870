import React, { useEffect, useRef, useState } from 'react';
import { sportsCount } from '../../../../actions/sports.action';
import DataTable from 'react-data-table-component';
import Spinner from '../../../_ui/Spinner/Spinner';
import { formatNumber } from '../../../../helpers/_helper';

const DeportesTable = () => {
    const isMounted = useRef(true);
    const [tableData, setTableData] = useState([]);
    const [pending, setPending] = useState(true);
    const columns = [
        {
            name: 'Deporte',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Total',
            selector: row => Number(row.sport_count),
            sortable: true,
        },
        {
            name: 'Activos',
            selector: row => Number(row.activos),
            sortable: true,
        },
        {
            name: 'Trial',
            selector: row => Number(row.trial),
            sortable: true,
        },
        {
            name: 'Baja Consolidada',
            selector: row => Number(row.bc),
            sortable: true,
        },
        {
            name: 'Baja No Consolidada',
            selector: row => Number(row.bnc),
            sortable: true,
        },
        {
            name: 'Ticket medio',
            selector: row => row.fee ? Number((row.fee / Number(row.activos)).toFixed(2)) : 0,
            sortable: true,
            cell: (row) => <span style={ { color: '#00ba34' } }>{ row.fee ? (row.fee / Number(row.activos)).toFixed(2) : 0 }€</span>,
        },
        {
            name: 'Ingresos mensuales',
            selector: row => Number(row.fee),
            sortable: true,
            cell: (row) => <span style={ { color: '#00ba34' } }>{ row.fee ? formatNumber(row.fee) : 0 }€</span>,
        },
    ];

    const getSportsCount = async () => {
        setPending(true);
        let getData = await sportsCount();
        if (getData) {
            setTableData(getData);
        }
        setPending(false);
    };

    useEffect(() => {
        getSportsCount();
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <DataTable
            columns={ columns }
            data={ tableData }
            highlightOnHover
            pointerOnHover
            fixedHeader
            progressPending={ pending }
            progressComponent={ <Spinner /> }
            fixedHeaderScrollHeight='600px'
        />
    );
};

export default DeportesTable;