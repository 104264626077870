export const getApp = (id) => {
	switch (id) {
		case '287':
			return 'Genérica';
		case '288':
			return 'Personalizada';
		case '289':
			return 'Clupik Master';
		default:
			return '-';
	}
};
export const getState = (id) => {
	switch (Number(id)) {
		case 1:
			return 'Baja';
		case 2:
			return 'Baja C';
		case 3:
			return 'Baja NC';
		case 4:
			return 'Activo';
		case 5:
			return 'Trial';
		case 6:
			return 'S/E';
		default:
			break;
	}
};
export const getContract = (id) => {
	switch (id) {
		case '280':
			return 'Club10';
		case '281':
			return 'Club20';
		case '283':
			return 'Club +';
		case '321':
			return 'Free';
		default:
			return '-';
	}
};
export const getPay = (id) => {
	switch (id) {
		case '285':
			return 'Anual';
		case '286':
			return 'Mensual';
		default:
			return '-';
	}
};

export const getLang = (id) => {
	switch (id) {
		case 185:
			return 'Castellano';

		case 186:
			return 'Catalán';

		case 187:
			return 'Euskera';

		case 188:
			return 'Gallego';

		case 189:
			return 'Portugués';

		case 190:
			return 'Inglés';

		case 191:
			return 'Italiano';

		case 192:
			return 'Francés';

		case 193:
			return 'Alemán';
		default:
			return '-';
	}
};

export const getSport = (id) => {
	switch (id) {
		case '9':
			return 'Baloncesto';
		case '10':
			return 'Fútbol';

		case '11':
			return 'Balonmano';

		case '12':
			return 'Rugby';

		case '13':
			return 'Otro';

		case '85':
			return 'Waterpolo';

		case '86':
			return 'Tenis de mesa';

		case '87':
			return 'Fútbol Sala';

		case '117':
			return 'Artes Marciales';

		case '121':
			return 'eSports ';

		case '122':
			return 'Multideporte';

		case '123':
			return 'Hockey hierba';

		case '124':
			return 'Hockey';

		case '197':
			return 'Hockey Hielo';

		case '125':
			return 'Tenis';

		case '126':
			return 'Padel';

		case '127':
			return 'Triatlón';

		case '128':
			return 'Voleibol';

		case '135':
			return 'Fútbol Americano';

		case '146':
			return 'Badminton';

		case '156':
			return 'Enduro';

		case '157':
			return 'Dardos';

		case '158':
			return 'Running';

		case '161':
			return 'Agility';

		case '162':
			return 'Gimnasia Rítmica';

		case '163':
			return 'Academia';

		case '164':
			return 'Gimnasio';

		case '165':
			return 'Cicloturismo';

		case '166':
			return 'Powerlifting';

		case '168':
			return 'Paddleboard';

		case '169':
			return 'Senderismo / Caminantes';

		case '171':
			return 'Atletismo';

		case '172':
			return 'Submarinismo';

		case '173':
			return 'Patinaje';

		case '174':
			return 'Act. Subacuáticas';

		case '175':
			return 'Ciclismo';

		case '176':
			return 'Remo';

		case '177':
			return 'Piragüismo';

		case '178':
			return 'Paddle surf';

		case '179':
			return 'Baile';

		case '180':
			return 'Aeromodelismo';

		case '181':
			return 'Golf';

		case '182':
			return 'Entrenamiento Personal';

		case '195':
			return 'Automovilismo';

		case '196':
			return 'Orientación';

		case '199':
			return 'Montañismo';

		case '209':
			return 'Pesca';

		case '212':
			return 'Tiro';

		case '213':
			return 'Natación';

		case '221':
			return 'Petanca';

		case '234':
			return 'Beisbol';

		case '238':
			return 'Hípica';

		case '239':
			return 'Deporte Playa';

		case '240':
			return 'Esquí';

		case '256':
			return 'Ajedrez';

		case '276':
			return 'Esgrima';

		case '308':
			return 'Sóftbol';

		case '324':
			return 'Bridge';

		case '325':
			return 'Bolos';

		case '362':
			return 'Trial';

		case '364':
			return 'Escalada';

		case '367':
			return 'Frontenis';

		case '371':
			return 'Jugger';
		default:
			return '-';
	}
};

export const getComercial = (id) => {
	switch (id) {
		case '26':
			return 'Santiago Díaz';

		case '365':
			return 'Paola';

		case '369':
			return 'Fabio';

		case '118':
			return 'Juan Pablo Goiburu';

		case '23':
			return 'David Acebes';

		case '336':
			return 'Antonio Hidalgo';

		case '25':
			return 'Borja Castillo';

		case '32':
			return 'Sama Diez';

		case '113':
			return 'Iñaki Rodríguez';

		case '27':
			return 'David García';

		case '28':
			return 'Jose Antonio Verdejo';

		case '29':
			return 'Jose Carlos Sánchez';

		case '24':
			return 'Sergio Sanchez';

		case '30':
			return 'Josu Larreina';

		case '31':
			return 'Nacho Seguimos';

		case '106':
			return 'Alain Cedillo';

		case '107':
			return 'Oscar Yenes';

		case '112':
			return 'Juanki Manrique';

		case '116':
			return 'Trini  Bou';

		case '129':
			return 'Fernando Jiménez';

		case '200':
			return 'Enrique García';

		case '339':
			return 'Fran Torre (Onil)';

		case '201':
			return 'Beñat';

		case '202':
			return 'Jorge Sanz';

		case '203':
			return 'Juanma Prieto';

		case '204':
			return 'Martina Vojtova';

		case '205':
			return 'Nacho Gordillo';

		case '206':
			return 'Sportmadness';

		case '208':
			return 'Toni Sastre';

		case '248':
			return 'Jorge Rodríguez';

		case '310':
			return 'Sara Barroso (Fake)';

		case '311':
			return 'Humurano';

		case '322':
			return 'Kyle';

		case '328':
			return 'Ignacio Tornos';

		case '333':
			return 'Gaby Darras';

		case '363':
			return 'Zeferino';
		default:
			return '-';
	}
};

export const getAtt = (id) => {
	switch (id) {
		case '114':
			return 'Gloria';
		case '115':
			return 'Quique';
		case '159':
			return 'Juan Pablo';
		case '318':
			return 'Baro (Brasil)';
		case '327':
			return 'Nico';
		case '335':
			return 'Sole';
		case '380':
			return 'Fabio';
		default:
			return '-';
	}
};

export const getPaymentType = (code) => {
	switch (code) {
		case 'STR':
			return 'Clupik Pay';
		case 'CAS':
			return 'Efectivo';
		case 'TRA':
			return 'Transferencia';
		case 'RDS':
			return 'Redsys';
		case 'DOM':
			return 'Domiciliación';
		default:
			return '-';
	}
};
