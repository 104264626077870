import React from 'react';

const ListElm = ({ icon, title, content, isLink = false, link }) => {
    return (
        <div className='list-elm'>
            <div className="icon">{ icon }</div>
            <div className="info">
                <p className="first">{ title }</p>
                {
                    isLink ? (
                        <a href={ link } target="new" className="second link">{ content }</a>
                    ) : (
                        <p className="second">{ content }</p>
                    )
                }
            </div>
        </div>
    );
};

export default ListElm;