import React, { useEffect, useRef, useState } from 'react';
import { clubCompetition, clupikCompetition } from '../../../../../actions/club.action';
import { ResponsivePie } from '@nivo/pie';
import Box from '../../../../_ui/Box/Box';
import Spinner from '../../../../_ui/Spinner/Spinner';
import Alert from '../../../../_ui/Alert/Alert';

const ClubCompetition = ({ clubId, points, season, module }) => {
    const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [competition, setCompetition] = useState({});

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const getCompetition = async () => {
            setCompetition({});
            let data = await clupikCompetition(clubId, season.id);
            if (data) {
                setCompetition(p => ({ ...p, competition: data }));
            }
            let dataBis = await clubCompetition(clubId, season.id);
            if (dataBis) {
                delete dataBis.ok;
                setCompetition(p => ({ ...p, teams: dataBis.competition?.teams, stadiums: dataBis.competition?.stadiums }));
            }
            setLoading(false);
        };

        if (isMounted.current) {
            clubId && getCompetition();
        }
    }, []);

    return (
        <Box subtitle={ 'Últimos 30 días' } expandable={ module } title="Competición" id="club-competition" points={ points }>
            {
                module ? (
                    loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className="preview">
                                <div className="each">
                                    <h6 className="h6-14-b">Partidos</h6>
                                    <p className="p4-14">{ competition?.competition?.games } partidos este mes</p>
                                </div>
                                <div className="each">
                                    <h6 className="h6-14-b">Equipos</h6>
                                    <p className="p4-14">{ competition?.teams } equipos esta temporada</p>
                                </div>
                                <div className="each">
                                    <h6 className="h6-14-b">Estadios</h6>
                                    <p className="p4-14">{ competition?.stadiums } estadios</p>
                                </div>

                            </div>
                            <div className="view">
                                <div className="graphs">

                                    <div className="left">
                                        <h5 className='h5-16-b'>Competiciones</h5>
                                        <ResponsivePie
                                            data={ [{ id: 'Automatizadas', label: 'Automatizadas: ' + competition?.competition?.organizers.filter(elm => elm.type === 'clupik').length, value: competition?.competition?.organizers.filter(elm => elm.type === 'clupik').length },
                                            { id: 'Manuales', label: 'Manuales: ' + competition?.competition?.organizers.filter(elm => elm.type === 'private' || elm.type === 'comunity').length, value: competition?.competition?.organizers.filter(elm => elm.type === 'private' || elm.type === 'comunity').length },
                                            ] }
                                            colors={ ['#F79C00', '#12A23A'] }
                                            margin={ { top: 0, right: 0, bottom: 24, left: 0 } }
                                            innerRadius={ 0.5 }
                                            activeOuterRadiusOffset={ 0 }
                                            borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                                            enableArcLabels={ false }
                                            enableArcLinkLabels={ false }
                                            arcLinkLabelsSkipAngle={ 10 }
                                            arcLinkLabelsTextColor="#333333"
                                            arcLinkLabelsThickness={ 2 }
                                            arcLinkLabelsColor={ { from: 'color' } }
                                            arcLabelsSkipAngle={ 10 }
                                            arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                                            legends={ [
                                                {
                                                    anchor: 'left',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 0,
                                                    itemsSpacing: 0,
                                                    itemWidth: 400,
                                                    itemHeight: 29,
                                                    itemTextColor: '#1c1c1c',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 12,
                                                    symbolShape: 'square',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ] }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )

                ) : (
                    <Alert text='Sin resultados. Este club no tiene activado el módulo de competiciones.' />
                )
            }
        </Box>
    );
};

export default React.memo(ClubCompetition);