export const dropList = [
	{
		id: 1,
		name: 'Último mes',
	},
	{
		id: 3,
		name: 'Últimos 3 meses',
	},
	{
		id: 6,
		name: 'Últimos 6 meses',
	},
	{
		id: 12,
		name: 'Últimos 12 meses',
	},
	{
		id: 18,
		name: 'Últimos 18 meses',
	},
	{
		id: 24,
		name: 'Últimos 24 meses',
	},
	{
		id: 36,
		name: 'Últimos 36 meses',
	},
	{
		id: 48,
		name: 'Últimos 48 meses',
	},
];
export const dropListClubs = [
	{
		id: 2,
		name: 'Baja Consolidada',
	},
	{
		id: 3,
		name: 'Baja no consolidada',
	},
	{
		id: 4,
		name: 'Clubs activos',
	},
	{
		id: 5,
		name: 'Clubs en trial',
	},
	{
		id: null,
		name: 'Todos',
	},
];
