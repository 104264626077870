import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerStyled = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 4px solid lightgrey;
	border-right: 4px solid lightgrey;
	border-bottom: 4px solid lightgrey;
	border-left: 4px solid grey;
	background: transparent;
	width: 40px;
	height: 40px;
	border-radius: 50%;
`;


const Spinner = ({ message }) => {
  return (
    <div style={ { padding: '24px', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }>
      <SpinnerStyled />
      { message && <small>{ message }</small> }
    </div>
  );
};

export default Spinner;
