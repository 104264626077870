import { fetchConToken, fetchSinToken } from '../helpers/fetch';
import Swal from 'sweetalert2';

export const startLogin = async (email, password) => {
	const resp = await fetchSinToken('auth/login', { email, password }, 'POST');
	const body = await resp.json();

	if (body.ok) {
		const loggedUser = {
			id: body.id,
			name: body.name,
			email: body.email,
			logged: true,
			token: body.token,
			role: body.role,
		};
		localStorage.setItem('clupik-user', JSON.stringify(loggedUser));
		localStorage.setItem('clupik-token', body.token);

		return loggedUser;
	} else {
		const errorMsg = body.msg;
		Swal.fire('Error', errorMsg, 'error');
		return false;
	}
};

export const newPassword = async (email, password) => {
	const resp = await fetchSinToken('auth/password', { email, password }, 'POST');
	const body = await resp.json();

	if (body.ok) {
		return true;
	} else {
		return false;
	}
};

export const startSignup = async (name, email, password) => {
	const resp = await fetchSinToken('auth/signup', { name, email, password }, 'POST');
	const body = await resp.json();

	if (body.ok) {
		return true;
	} else {
		const errorMsg = body.msg;
		Swal.fire('Error', errorMsg, 'error');
	}
};

export const confirmUser = async (code, name, email) => {
	const resp = await fetchSinToken(`auth/confirm/${code}`, {}, 'PUT');
	const body = await resp.json();
	if (body.ok) {
		const signedupUser = {
			id: body.user._id,
			name: body.user.name,
			email: body.user.email,
			token: body.token,
			status: body.user.status,
			role: body.user.role,
			logged: true,
		};
		localStorage.setItem('clupik-user', JSON.stringify(signedupUser));
		localStorage.setItem('clupik-token', body.token);

		return signedupUser;
	} else {
		const errorMsg = body.msg;
		Swal.fire('Error', errorMsg, 'error');
		return false;
	}
};
export const newPassUser = async (code, email, password) => {
	const resp = await fetchConToken(`auth/new-password`, { email, password, passChangeCode: code }, 'PATCH', code);
	const body = await resp.json();
	if (body.ok) {
		const signedupUser = {
			id: body.user._id,
			name: body.user.name,
			email: body.user.email,
			token: body.token,
			status: body.user.status,
			role: body.user.role,
			logged: true,
		};
		localStorage.setItem('clupik-user', JSON.stringify(signedupUser));
		localStorage.setItem('clupik-token', body.token);

		return signedupUser;
	} else {
		const errorMsg = body.msg;
		Swal.fire('Error', errorMsg, 'error');

		return false;
	}
};
export const hasToken = async () => {
	const resp = await fetchConToken('auth/renew');
	const body = await resp.json();
	if (body.ok) {
		const signedupUser = {
			id: body.id,
			name: body.name,
			email: body.email,
			token: body.token,
			status: body.status,
			role: body.role,
			logged: true,
		};
		localStorage.setItem('clupik-user', JSON.stringify(signedupUser));
		localStorage.setItem('clupik-token', body.token);
		return true;
	} else {
		return false;
	}
};
