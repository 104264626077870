import React from 'react'

const ValidationScreen = () => {
    return (
        <div>
            Recibiras un email para confirmar el registro :)
        </div>
    )
}

export default ValidationScreen
