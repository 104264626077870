import React, { useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { newPassUser } from '../../../actions/auth.action';
import useForm from '../../../hooks/useForm';
import { AuthContext } from '../../../reducers/auth/AuthContext';
import { types } from '../../../types/types';

const NewPassword = () => {
    const { tokenpass } = useParams();
    const { search } = useLocation();
    const { dispatch } = useContext(AuthContext);
    const [message, setMessage] = useState({ on: false, message: '' });

    const { values, handleInputChange } = useForm({});
    const { password, password2 } = values;
    const handlePasswordChange = async (e) => {
        let email = search.split('=')[1];
        e.preventDefault();
        if (password === password2) {
            const newPasswordUser = await newPassUser(tokenpass, email, values.password);
            if (newPasswordUser) {
                dispatch({ type: types.login, payload: newPasswordUser });
            } else {
                setMessage({ on: true, message: `Algo pasó con el cambio de contraseña de usuario` });
            }
        } else {
            setMessage({ on: true, message: `Las contraseñas deben de coincidir` });
        }
    };

    return (
        <>
            <section className="login">
                <figure>
                    <img src={ process.env.REACT_APP_COMPANY_LOGO_BIG } alt="" />
                </figure>
                <p>Introduce una contraseña nueva</p>
                <form onSubmit={ handlePasswordChange }>
                    <label>Contraseña</label>
                    <input type='password' onChange={ handleInputChange } placeholder='Contraseña' name='password' />
                    <label>Repite Contraseña</label>
                    <input type='password' onChange={ handleInputChange } placeholder='Repite Contraseña' name='password2' />

                    <button type='submit' className='my-btn mini'>
                        Cambiar contraseña
                    </button>
                </form>
                { message.on && <p className='p3-13'>{ message.message }</p> }

            </section>

        </>
    );
};

export default NewPassword;