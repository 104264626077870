import React from 'react';

import MainTitle from '../../_ui/MainTitle/MainTitle';
import CountryChart from './CountryChart/CountryChart';

const Countries = () => {
    return (
        <div id="dash-comercial">
            <MainTitle title="Países" />
            <CountryChart />
        </div>
    );
};

export default Countries;
