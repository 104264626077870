import React from 'react';

import ProductUsersChart from '../../Charts/ProductUsersChart/ProductUsersChart';
import MainTitle from '../../_ui/MainTitle/MainTitle';

const ProductDataBase = () => {
    return (
        <div id="dash-comercial">
            <MainTitle title="Base de datos" />
            <ProductUsersChart />
        </div>
    );
};

export default ProductDataBase;
