import React from 'react';

import Spinner from '../../_ui/Spinner/Spinner';

import { formatNumber } from '../../../helpers/_helper';

import { FaChevronRight } from 'react-icons/fa';

const MarketingFunnel = ({ leads, oport, demos, clients, loading }) => {
    return (
        <article id="funnel">
            <h4 className="h4-10">Funnel de conversión</h4>
            { loading ? (
                <Spinner />
            ) : (
                <div className="data">
                    <div className="each">
                        <h5 className="h5-16">Leads</h5>
                        <h3 className="h3-24">100%</h3>
                        <p className="p2-16">{ formatNumber(leads) }</p>
                    </div >
                    <FaChevronRight className="icon-fix" />
                    <div className="each">
                        <h5 className="h5-16">Oportunidades</h5>
                        <h3 className="h3-24">{ formatNumber(((oport / leads) * 100).toFixed(2)) }%</h3>
                        <p className="p2-16">{ formatNumber(oport) }</p>
                    </div>
                    <FaChevronRight className="icon-fix" />
                    <div className="each">
                        <h5 className="h5-16">Demos</h5>
                        <h3 className="h3-24">{ formatNumber(((demos / leads) * 100).toFixed(2)) }%</h3>
                        <p className="p2-16">{ formatNumber(demos) }</p>
                    </div>
                    <FaChevronRight className="icon-fix" />
                    <div className="each">
                        <h5 className="h5-16">Clientes</h5>
                        <h3 className="h3-24">{ formatNumber(((clients / leads) * 100).toFixed(2)) }%</h3>
                        <p className="p2-16">{ formatNumber(clients) }</p>
                    </div>
                </div >
            ) }
        </article>
    );
};

export default MarketingFunnel;
