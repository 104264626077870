import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { getClientsCount } from '../../../actions/business.action';

import Box from '../../_ui/Box/Box';
import Spinner from '../../_ui/Spinner/Spinner';
import Dropdown from '../../_ui/DropDown/DropDown';

import { firstDay, meses } from '../../../helpers/dates';
import { dropList } from '../../../helpers/droplist';
import { formatNumber } from '../../../helpers/_helper';

import dayjs from 'dayjs';
import { ResponsiveLine } from '@nivo/line';
import { MdChevronRight } from 'react-icons/md';
import 'react-datepicker/dist/react-datepicker.css';

const ClientsChart = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [wonClubs, setWonClubs] = useState(0);
	const [lostCClubs, setLostCClubs] = useState(0);
	const [lostNClubs, setLostNClubs] = useState(0);
	const [trialClubs, setTrialClubs] = useState(0);

	const getData = async (qt) => {
		const data = await getClientsCount(firstDay(qt), qt);
		const trialGraph = {
			id: 'Trial',
			data: [],
		};
		const wonGraph = {
			id: 'Activos',
			data: [],
		};
		const lostCGraph = {
			id: 'Baja C',
			data: [],
		};
		const lostNGraph = {
			id: 'Baja NC',
			data: [],
		};
		const won = data.won;
		let wonAdd = won?.length > 0 ? won.reduce((a, b) => ({ count: a.count + b.count })) : 0;
		setWonClubs(wonAdd?.count);
		won?.forEach((ad) => {
			wonGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
		});
		setData((data) => [...data, wonGraph]);

		const trial = data.trial;
		let trialAdd = trial?.length > 0 ? trial.reduce((a, b) => ({ count: a.count + b.count })) : 0;
		setTrialClubs(trialAdd?.count);
		trial?.forEach((ad) => {
			trialGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
		});
		setData((data) => [...data, trialGraph]);

		const lostC = data.lostC;
		let lostCAdd = lostC?.length > 0 ? lostC.reduce((a, b) => ({ count: a.count + b.count })) : 0;
		setLostCClubs(lostCAdd?.count);
		lostC?.forEach((ad) => {
			lostCGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
		});
		setData((data) => [...data, lostCGraph]);

		const lostN = data.lostN;
		let lostNAdd = lostN?.length > 0 ? lostN.reduce((a, b) => ({ count: a.count + b.count })) : 0;
		setLostNClubs(lostNAdd?.count);
		lostN?.forEach((ad) => {
			lostNGraph.data.push({ x: (ad.month = meses[dayjs(ad.month).get('month')] + "'" + dayjs(ad.month).get('year')), y: ad.count });
		});
		setData((data) => [...data, lostNGraph]);
		setLoading(false);
	};

	const firstData = useCallback(() => {
		setLoading(true);
		getData(6);
	}, []);

	useEffect(() => {
		setLoading(true);
		setCurrentSelection('Últimos 6 meses');
		setData([]);
		firstData();
	}, [firstData]);

	const selectData = (id) => {
		setLoading(true);
		setWonClubs(0);
		setLostCClubs(0);
		setLostNClubs(0);
		setTrialClubs(0);
		setData([]);
		getData(id);
	};

	const [currentSelection, setCurrentSelection] = useState('Últimos 6 meses');

	return (
		<Box id='client-chart' title='Clientes'>
			<Dropdown list={ dropList } method={ selectData } currentSelection={ currentSelection } setCurrentSelection={ setCurrentSelection } />
			{
				loading ? (
					<Spinner />
				) : (
					<div className='fix'>
						<article className='left'>
							<ResponsiveLine
								data={ data }
								margin={ { top: 5, right: 10, bottom: 100, left: 30 } }
								xScale={ { type: 'point' } }
								yScale={ { type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false } }
								yFormat='>-0,.2~f'
								axisTop={ null }
								axisRight={ null }
								axisBottom={ {
									orient: 'bottom',
									tickSize: 5,
									tickPadding: 10,
									tickRotation: -90,
									legendOffset: 0,
									legendPosition: 'middle',
								} }
								axisLeft={ {
									orient: 'left',
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legendOffset: -40,
									legendPosition: 'middle',
								} }
								colors={ { scheme: 'paired' } }
								enableGridX={ false }
								pointSize={ 10 }
								pointColor={ { theme: 'background' } }
								pointBorderWidth={ 2 }
								pointBorderColor={ { from: 'serieColor' } }
								pointLabelYOffset={ -12 }
								useMesh={ true }
								enableSlices='x'
								legends={ [
									{
										anchor: 'bottom-left',
										direction: 'row',
										justify: false,
										translateX: -16,
										translateY: 90,
										itemWidth: 91,
										itemHeight: 10,
										itemsSpacing: 4,
										symbolSize: 10,
										symbolShape: 'circle',
										itemDirection: 'left-to-right',
										itemTextColor: '#777',
										effects: [
											{
												on: 'hover',
												style: {
													itemBackground: 'rgba(0, 0, 0, .03)',
													itemOpacity: 1,
												},
											},
										],
									},
								] }
							/>
						</article>
						<article className='right'>
							<div className='data'>
								<div className='addition'>
									<h1>{ formatNumber(wonClubs) }</h1>
									<div>
										<small className='p2-16'>Activos</small>
									</div>
								</div>
								<div className='addition'>
									<h2>{ formatNumber(trialClubs) }</h2>
									<div>
										<small className='p2-16'>Trial</small>
									</div>
								</div>
								<div className='addition'>
									<h2>{ formatNumber(lostCClubs) }</h2>
									<div>
										<small className='p2-16'>Baja Consolidada</small>
									</div>
								</div>
								<div className='addition'>
									<h2>{ formatNumber(lostNClubs) }</h2>
									<div>
										<small className='p2-16'>Baja No Consolidada</small>
									</div>
								</div>
							</div>
							<Link className='client-list-link' to='/comercial/clientes'>
								Clientes <MdChevronRight />
							</Link>
						</article>
					</div >

				)
			}
		</Box>
	);
};

export default ClientsChart;
