import { fetchConToken } from '../helpers/fetch';

export const getProductUsers = async (qt, state = 4) => {
	try {
		const resp = await fetchConToken(state ? `db/users?amount=${qt}&state=${state}` : `db/users?amount=${qt}`, 'GET');
		const body = await resp.json();
		let aux = Object.entries(body);
		let result = {};
		result.total = body.totalUsers;
		delete aux.totalUsers;
		for (let i = 0; i < aux.length; i++) {
			const selected = aux[i];
			const key = selected[0];
			const subkey = selected[1];
			const inaux = [];
			for (const property in subkey) {
				inaux.push({ x: property, y: Number(subkey[property]) });
			}
			result = {
				...result,
				[key]: inaux,
			};
		}
		return result;
	} catch (error) {
		console.log(error);
	}
};

export const usersByRole = async (qt, state = 4) => {
	let url;
	if (state) {
		url = `db/user-rol?amount=${qt}&state=${state}`;
	} else {
		url = `db/user-rol?amount=${qt}`;
	}
	try {
		const resp = await fetchConToken(url, 'GET');
		const body = await resp.json();
		return body;
	} catch (error) {
		console.log(error);
	}
};
