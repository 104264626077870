import dayjs from 'dayjs';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaCalculator, FaCoins, FaPhoneAlt, FaShoppingCart, FaFileContract } from 'react-icons/fa';
import { getApp, getAtt, getComercial, getLang, getPay, getSport, getState } from '../../../../../../helpers/random';

const ModalInfo = ({ handleClose, show, deal, organization, state }) => {
    return (
        <>
            <Modal className='modal-info' show={ show } onHide={ handleClose } size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Datos de contacto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="content">

                        <article className="each-data">
                            <div className="icon-title">
                                <FaPhoneAlt />
                                <h4 className="h4-20">Contacto</h4>
                            </div>
                            <div className="info">
                                <div className="left">
                                    <p><strong>Nombre: </strong>{ deal?.person_id.name }</p>
                                    <p><strong>Teléfono: </strong>{ deal?.person_id.phone[0].value }</p>
                                    <p><strong>Email: </strong>{ deal?.person_id.email[0].value }</p>
                                </div>
                                <div className="right">
                                    <p><strong>País: </strong>{ organization?.address_country }</p>
                                    <p><strong>Idioma(web): </strong>{ organization && getLang(organization['92efeda13bdc040cf88c9c7628a8900b7902874f']) }</p>
                                </div>
                            </div>
                        </article>

                        <article className="each-data">
                            <div className="icon-title">
                                <FaShoppingCart />
                                <h4 className="h4-20">Producto</h4>
                            </div>
                            <div className="info">
                                <div className="left">
                                    <p><strong>Estado: </strong>{ getState(state) }</p>
                                    <p><strong>Tipo de plan: </strong>{ getPay(deal["578a7b190ac3254ceed77cf718f6ba675162fc92"]) }</p>
                                    <p><strong>App: </strong>{ getApp(deal["89f06122bc10f606bb278b772f9326b9c314475e"]) }</p>
                                </div>
                                <div className="right">
                                    <p><strong>Fecha de publicación: </strong>{ deal?.won_time ? dayjs(deal?.won_time).format('DD/MM/YYYY') : dayjs(deal?.first_won_time).format('DD/MM/YYYY') }</p>
                                    <p><strong>Fecha de baja: </strong>{ deal && deal['99a0c2cc1a8a618675d67354771dda5fbbdc5dc4'] ? deal['99a0c2cc1a8a618675d67354771dda5fbbdc5dc4'] : '-' }</p>
                                    <p><strong>Deportes: </strong>{ organization && getSport(organization['5645c67fdaff361559001bb1c98dc8b80fcab4a3']) }</p>
                                </div>
                            </div>
                        </article>

                        <article className="each-data">
                            <div className="icon-title">
                                <FaCoins />
                                <h4 className="h4-20">Datos económicos</h4>
                            </div>
                            <div className="info">
                                <div className="left">
                                    <p><strong>Precio año 1: </strong>{ organization && organization['0f38c50d944705bc4032b04342b7352ec7314cac'] ? organization['0f38c50d944705bc4032b04342b7352ec7314cac'] : '-' }</p>
                                    <p><strong>Precio año 2: </strong>{ organization && organization['0af0fec76f08a0d9298d38dcea5c994071764a4b'] ? organization['0af0fec76f08a0d9298d38dcea5c994071764a4b'] : '-' }</p>
                                    <p><strong>Precio año 3: </strong>{ organization && organization['2f027da8befe3762f6ef9d3eca0f8f953d6ff210'] ? organization['2f027da8befe3762f6ef9d3eca0f8f953d6ff210'] : '-' }</p>
                                    <p><strong>Fecha prevista inicio de pago: </strong>{ deal?.expected_close_date ? dayjs(deal?.expected_close_date).format('DD/MM/YYYY') : '-' }</p>
                                </div>
                                <div className="right">
                                    <p><strong>Persona atención al cliente: </strong>{ organization && getAtt(organization['5b6cf50d218862a34f8a8a07edd492cd1f61d7fa']) }</p>
                                    <p><strong>Entrada del contacto: </strong>{ dayjs(deal?.add_time).format('DD/MM/YYYY') }</p>
                                    <p><strong>Comercial asignado: </strong>{ organization && getComercial(organization['086c48af47c8075edc9a5120eaff0879b8ff6f36']) }</p>
                                </div>
                            </div>
                        </article>

                        <article className="each-data">
                            <div className="icon-title">
                                <FaCalculator />
                                <h4 className="h4-20">Datos de pago</h4>
                            </div>
                            <div className="info">
                                <div className="left">
                                    <p><strong>Titular de la cuenta: </strong>{ organization && organization['8a6d8607d1ea2b77a0bfd4d717b16dbf27573030'] ? organization['8a6d8607d1ea2b77a0bfd4d717b16dbf27573030'] : '-' }</p>
                                    <p><strong>IBAN: </strong>{ organization && organization['01ff9993785ef3592d7fe82dfa71c0adeb18de03'] ? organization['01ff9993785ef3592d7fe82dfa71c0adeb18de03'] : '-' }</p>
                                </div>
                            </div>
                        </article>

                        <article className="each-data">
                            <div className="icon-title">
                                <FaFileContract />
                                <h4 className="h4-20">Información del contrato</h4>
                            </div>
                            <div className="info">
                                <div className="left">
                                    <p><strong>Nombre y apellidos: </strong>{ organization && organization['7a9ff08adfdfaa5d4267ee6048de7f993b08d37a'] ? organization['7a9ff08adfdfaa5d4267ee6048de7f993b08d37a'] : '-' }</p>
                                    <p><strong>DNI del representante: </strong>{ organization && organization['af4607f9bd78d625be0b508b9978de28b8ed3243'] ? organization['af4607f9bd78d625be0b508b9978de28b8ed3243'] : '-' }</p>
                                    <p><strong>CIF: </strong>{ organization && organization['8fa6ef27b6d9354b33f628d02168b03221a36591'] ? organization['8fa6ef27b6d9354b33f628d02168b03221a36591'] : '-' }</p>
                                    <p><strong>Dirección fiscal: </strong>{ organization && organization['a27a4261b72be5231cf95a7daeac964c889a4ef3'] ? organization['a27a4261b72be5231cf95a7daeac964c889a4ef3'] : '-' }</p>
                                </div>
                                <div className="right">
                                    <p><strong>C.P: </strong>{ organization && organization['b990892c0ce92bafcac50b07886b7a77abd90f14'] ? organization['b990892c0ce92bafcac50b07886b7a77abd90f14'] : '-' }</p>
                                    <p><strong>Provincia: </strong>{ organization && organization['deecf861fe654803a542f6b72fcfd26058005596'] ? organization['deecf861fe654803a542f6b72fcfd26058005596'] : '-' }</p>
                                    <p><strong>Comunidad autónoma: </strong>{ organization && organization['7c30229f182be8f55b480deb2bae31dfafa6eddf'] ? organization['7c30229f182be8f55b480deb2bae31dfafa6eddf'] : '-' }</p>
                                    <p><strong>País: </strong>{ organization && organization['0d6f1828c043f67f2ae400dd04fdd9042b39bbc3'] ? organization['0d6f1828c043f67f2ae400dd04fdd9042b39bbc3'] : '-' }</p>
                                </div>
                            </div>
                        </article>

                    </div>

                </Modal.Body>
            </Modal>
        </>);
};

export default ModalInfo;