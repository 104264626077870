import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getClubInfo } from '../../../../actions/club.action';
import { getCurrentSeason } from '../../../../actions/sport-structure.action';
import BackButton from '../../../_ui/BackButton/BackButton';
import MainTitle from '../../../_ui/MainTitle/MainTitle';
import Spinner from '../../../_ui/Spinner/Spinner';
import ClubAdmon from './ClubAdmon/ClubAdmon';
import ClubCom from './ClubCom/ClubCom';
import ClubCompetition from './ClubCompetition/ClubCompetition';
import ClubInfo from './Clubinfo/ClubInfo';
import ClubPayments from './ClubPayments/ClubPayments';
import ClubStore from './ClubStore/ClubStore';
import ClubTrainings from './ClubTrainings/ClubTrainings';

const Club = (props) => {
    const isMounted = useRef(false);
    const { clubId } = useParams();
    const [club, setClub] = useState(null);
    const [season, setSeason] = useState(null);
    const [loading, setLoading] = useState(false);

    const findSeason = async (club) => {
        if (club?.clubConfig.season) {
            setSeason(club?.clubConfig.season);
        } else {
            let aux = await getCurrentSeason(club?.clubConfig.seasonTypes.code);
            if (aux) {
                setSeason(aux);
            }
        }

    };

    useEffect(() => {
        isMounted.current = true;
        setLoading(true);
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        setSeason(null);
        const getClub = async () => {
            const data = await getClubInfo(clubId);
            data && setClub(data);
            findSeason(data);
            setLoading(false);
        };
        if (isMounted.current) {
            setLoading(true);
            getClub();
        }

    }, [clubId]);


    return (
        <>
            <BackButton title='Clientes' link='/comercial/clientes' />
            <section id='clientes' className='grid'>
                {
                    loading ? (
                        <Spinner message={ `Cargando datos del club seleccionado` } />
                    ) : (
                        <>
                            <MainTitle title={ club?.name } clubUrl={ club?.clubUrl } isClub clubId={ clubId } points={ club?.deal && club?.deal['5f6a4a09ac7238725b4f8a60f2d8bfb4ca3daa71'] } />
                            { season && clubId &&
                                <>
                                    <ClubInfo deal={ club?.deal } organization={ club?.organization } state={ club?.state } />
                                    <ClubPayments holded={ club?.holded } />
                                    <ClubAdmon module={ club?.clubModules.accounting === 'active' } coin={ club?.clubConfig.coin.symbol } season={ season } clubId={ clubId } points={ club?.deal && club?.deal['2ca1b4bafd2fea836c9f820550d81bdd607495be'] } />
                                    <ClubCom clubId={ clubId } points={ club?.deal && club?.deal['b3f3cbaf1cd5324487912a84683d48b278025076'] } />
                                    <ClubCompetition module={ club?.clubModules.competition === 'active' } season={ season } clubId={ clubId } points={ club?.deal && club?.deal['141a9b72deeed72987366ac37d72fba909218eb1'] } />
                                    <ClubTrainings season={ season } clubId={ clubId } points={ club?.deal && club?.deal['6cea2d8538f4b519ecb471b91877eb7fe00ffebb'] } />
                                    <ClubStore coin={ club?.clubConfig.coin.symbol } clubId={ clubId } module={ club?.clubModules.shop === 'active' } points={ club?.deal && club?.deal['c649a7d496f59d1eb5d0ff9d010fb8bad43beb07'] } />
                                </>
                            }
                        </>
                    )
                }
            </section>
        </>
    );
};

export default Club;