import React, { useEffect, useState } from 'react';

import { getContractsTable } from '../../../actions/contracts.action';

import Spinner from '../../_ui/Spinner/Spinner';

import { formatNumber, getContractState } from '../../../helpers/_helper';

import DataTable from 'react-data-table-component';

const ContratosTable = () => {
    const [tableData, setTableData] = useState([]);
    const [pending, setPending] = useState(false);
    const getTableData = async () => {
        const getData = await getContractsTable();
        if (getData.length > 0) {
            setTableData(getData);
        }
        setPending(false);
    };
    const columns = [
        {
            name: 'Contrato',
            selector: row => getContractState(row.contractType),
            sortable: true,
        },
        {
            name: 'Activos',
            selector: row => Number(row.active),
            sortable: true,

        },
        {
            name: 'Trial',
            selector: row => Number(row.trial),
            sortable: true,

        },
        {
            name: 'Bajas No Consolidadas',
            selector: row => Number(row.bnc),
            sortable: true,

        },
        {
            name: 'Bajas Consolidadas',
            selector: row => Number(row.bc),
            sortable: true,

        },
        {
            name: 'Totales',
            selector: row => Number(row.total),
            sortable: true,

        },
        {
            name: 'Ticket medio',
            selector: row => row.fee ? Number((row.fee / Number(row.active)).toFixed(2)) : 0,
            sortable: true,
            cell: (row) => <span style={ { color: '#00ba34' } }>{ row.fee ? (row.fee / Number(row.active)).toFixed(2) : 0 }€</span>,
        },
        {
            name: 'Ingresos mensuales',
            selector: row => Number(row.fee),
            sortable: true,
            cell: (row) => <span style={ { color: '#00ba34' } }>{ row.fee ? formatNumber(row.fee) : 0 }€</span>,

        },

    ];


    useEffect(() => {
        setPending(true);
        getTableData();

    }, []);

    return (
        <DataTable
            columns={ columns }
            data={ tableData }
            highlightOnHover
            pointerOnHover
            fixedHeader
            progressPending={ pending }
            progressComponent={ <Spinner /> }
        />
    );
};

export default ContratosTable;