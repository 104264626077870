import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { confirmUser } from '../../../actions/auth.action';
import { AuthContext } from '../../../reducers/auth/AuthContext';
import { types } from '../../../types/types';

const ConfirmUserScreen = () => {
    const { code } = useParams();
    const { dispatch } = useContext(AuthContext);
    const history = useHistory();

    const buttonConfirm = async () => {
        const confirmedUser = await confirmUser(code);
        if (confirmedUser) {
            dispatch({ type: types.signup, payload: confirmedUser });
            history.push('/');
        }
        history.push('/');
    };

    return (
        <>
            <p className='p1-20'>Para confirmar tu usuario, pincha en el siguiente link. </p>
            <button onClick={ buttonConfirm }>Confirmar</button>
        </>
    );
};

export default ConfirmUserScreen;
