import { fetchConToken } from '../helpers/fetch';

export const getContractGraph = async (qt, state, contract) => {
	let url;
	if (state) {
		url = `general-data/contract/graph?amount=${qt}&state=${state}`;
	} else {
		url = `general-data/contract/graph?amount=${qt}`;
	}
	const resp = await fetchConToken(url, 'GET');
	const body = await resp.json();
	let aux = Object.entries(body.result);
	let result = {};
	for (let i = 0; i < aux.length; i++) {
		const selected = aux[i];
		const key = selected[0];
		const subkey = selected[1];
		const inaux = [];
		for (const property in subkey) {
			inaux.push({ x: property, y: Number(subkey[property]) });
		}
		result = {
			...result,
			[key]: inaux,
		};
	}
	return result;
};
export const getContractsTable = async () => {
	const resp = await fetchConToken('general-data/contract', 'GET');
	const body = await resp.json();
	if (body.ok) {
		let result = body.result.map((elm) => elm.total !== '0' && elm);
		result = result.filter((elm) => elm);
		return result;
	} else {
		const errorMsg = body.err;
		console.log(errorMsg);
	}
};
