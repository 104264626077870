import React, { useContext, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ConfirmUserScreen from '../components/auth/ConfirmUserScreen/ConfirmUserScreen';
import LoginScreen from '../components/auth/LoginScreen/LoginScreen';
import NewPassword from '../components/auth/NewPassword/NewPassword';
import PasswordRenew from '../components/auth/PasswordRenew/PasswordRenew';
import SignupScreen from '../components/auth/SignupScreen/SignupScreen';
import Clientes from '../components/Comercial/Clientes/Clientes';
import Club from '../components/Comercial/Clientes/Club/Club';
import Contratos from '../components/Comercial/Contratos/Contratos';
import Countries from '../components/Comercial/Countries/Countries';
import DashboardComercial from '../components/Comercial/DashboardComercial';
import Deportes from '../components/Comercial/Deportes/Deportes';
import DashboardScreen from '../components/DashboardScreen/DashboardScreen';
import MarketingScreen from '../components/Marketing/MarketingScreen';
import ProductDataBase from '../components/Product/ProductDataBase/ProductDataBase';
import SportStructure from '../components/Product/SportStructure/SportStructure';
import ValidationScreen from '../components/ValidationScreen/ValidationScreen';
import DashNav from '../components/_ui/DashNav/DashNav';
import DashProfile from '../components/_ui/DashProfile/DashProfile';
import { AuthContext } from '../reducers/auth/AuthContext';


const AppRouter = () => {
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, [user]);

	return (
		<Router>
			<div>
				{ user.token ? (
					<div className='dashboard-in'>
						<DashNav />

						<main id="main-screen">
							<DashProfile />
							<Switch>
								<Route exact path='/' component={ DashboardScreen } />
								<Route exact path='/negocio' component={ DashboardScreen } />
								<Route exact path='/negocio/situacion' component={ DashboardScreen } />
								<Route exact path='/comercial' component={ DashboardComercial } />
								<Route exact path='/comercial/clientes' component={ Clientes } />
								<Route exact path='/comercial/clientes/club/:clubId' component={ Club } />
								<Route exact path='/comercial/deportes' component={ Deportes } />
								<Route exact path='/comercial/contratos' component={ Contratos } />
								<Route exact path='/comercial/paises' component={ Countries } />
								<Route exact path='/marketing' component={ MarketingScreen } />
								<Route exact path='/marketing/clientes' component={ DashboardScreen } />
								<Route exact path='/producto' component={ DashboardScreen } />
								<Route exact path='/producto/users' component={ ProductDataBase } />
								<Route exact path='/producto/estructura-deportiva' component={ SportStructure } />
								<Route exact path='/producto/inscripciones' component={ DashboardScreen } />
								<Route exact path='/producto/movimientos' component={ DashboardScreen } />
								<Route exact path='/producto/publicaciones' component={ DashboardScreen } />
								<Route exact path='/producto/notificaciones' component={ DashboardScreen } />
								<Route exact path='/producto/entrenamientos' component={ DashboardScreen } />
								<Route exact path='/producto/partidos' component={ DashboardScreen } />
								{ user.role === 'admin' && <Route exact path='/registro' component={ SignupScreen } /> }
								<Redirect to='/' />
							</Switch>
						</main>

					</div>
				) : (
					<div className="login-style">
						<main className='login-container'>
							<Switch>
								<Route exact path='/' component={ LoginScreen } />
								<Route exact path='/password-login' component={ LoginScreen } />
								<Route path='/confirmar-usuario/:code' component={ ConfirmUserScreen } />
								<Route path='/nueva-contrasena/:tokenpass' component={ NewPassword } />
								<Route path='/password' component={ PasswordRenew } />
								<Route path='/validacion' component={ ValidationScreen } />
								<Redirect to='/' />
							</Switch>
							<div className="copyright">
								<small>&copy; Clupik S.L.</small>
							</div>
						</main>
					</div>
				) }
			</div>
		</Router>
	);
};

export default AppRouter;
