import React, { useCallback, useEffect, useState } from 'react';

import { getSeasons, getTeamsAndRolesBySeason, getTeamStructure } from '../../../../actions/sport-structure.action';

import { dropListClubs } from '../../../../helpers/droplist';
import { formatNumber } from '../../../../helpers/_helper';

import Acclaration from '../../../_ui/Acclaration/Acclaration';
import Box from '../../../_ui/Box/Box';
import Dropdown from '../../../_ui/DropDown/DropDown';
import Spinner from '../../../_ui/Spinner/Spinner';

import DataTable from 'react-data-table-component';

const SportStructureTable = () => {
    const [data, setData] = useState({ teams: 0, roles: [] });
    const [droplistEU, setDroplistEU] = useState([]);
    const [droplistLAT, setDroplistLAT] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [pending, setPending] = useState(true);

    const [eu, setEu] = useState({ id: 35, name: 'Selecciona una temporada Europea' });
    const [lat, setLat] = useState({ id: null, name: 'Selecciona una temporada LATAM' });
    const [clubState, setClubState] = useState({ id: 4, name: 'Clubs Activos' });

    const getTeams = useCallback(async (season, state) => {
        let getData = await getTeamsAndRolesBySeason(season, state);
        setData(data => ({ ...data, teams: getData.teamsCount[0].count, roles: getData.roles }));
    }, []);

    const getSeasonsDroplist = async () => {
        let getData = await getSeasons();
        let eu = getData.filter(data => data.seasonType === 'SAP');
        let lat = getData.filter(data => data.seasonType === 'SAC');
        setDroplistEU(eu);
        setDroplistLAT(lat);
        setEu({ name: eu[0].name, id: eu[0].id });
    };

    const getStructure = async (season, state) => {
        let getData = await getTeamStructure(season, state);
        setTableData(getData);
        setPending(false);
    };

    useEffect(() => {
        setDroplistEU([]);
        setDroplistLAT([]);
        getStructure(35, 4);
        getTeams(35, 4);
        getSeasonsDroplist();
    }, [getTeams]);

    const selectClubState = (id) => {
        setClubState(c => ({ ...c, id: id }));
        let seasonSelected = eu.id ? eu.id : lat.id;
        getTeams(seasonSelected, id);
        getStructure(seasonSelected, id);
    };

    const selectClubStateName = (name) => {
        setClubState(c => ({ ...c, name: name }));
    };
    const selectEuState = (id) => {
        setEu(c => ({ ...c, id: id }));
        setPending(true);
        setData({ teams: 0, roles: [] });
        setTableData([]);
        getTeams(id, clubState.id);
        getStructure(id, clubState.id);
        setLat(c => ({ id: null, name: 'Selecciona una temporada LATAM' }));
    };
    const selectEuStateName = (name) => {
        setEu(c => ({ ...c, name: name }));
    };
    const selectLatState = (id) => {
        setLat(c => ({ ...c, id: id }));
        setPending(true);
        setData({ teams: 0, roles: [] });
        setTableData([]);
        getTeams(id, clubState.id);
        getStructure(id, clubState.id);
        setEu(c => ({ id: null, name: 'Selecciona una temporada Europea' }));
    };
    const selectLatStateName = (name) => {
        setLat(c => ({ ...c, name: name }));
    };


    const columns = [
        {
            name: 'Deporte',
            selector: row => row.sport,
            sortable: true,
            grow: 2
        },
        {
            name: 'Equipos',
            selector: row => row.sport_count,
            sortable: true,
            right: true,
        },
        {
            name: 'Coordinadores',
            selector: row => row.Coordinator ? row.Coordinator : '-',
            sortable: true,
            right: true,
        },
        {
            name: 'Entrenadores',
            selector: row => row.Coach ? row.Coach : '-',
            sortable: true,
            right: true,
        },
        {
            name: 'Jugadores',
            selector: row => row.Player ? row.Player : '-',
            sortable: true,
            right: true,
        },
    ];

    return (
        <Box id="client-chart" title="Estructura deportiva">
            <div className="drops">
                <Dropdown list={ dropListClubs } method={ selectClubState } currentSelection={ clubState.name } setCurrentSelection={ selectClubStateName } />
                <Dropdown list={ droplistEU } method={ selectEuState } currentSelection={ eu.name } setCurrentSelection={ selectEuStateName } />
                <Dropdown list={ droplistLAT } method={ selectLatState } currentSelection={ lat.name } setCurrentSelection={ selectLatStateName } />
            </div>

            {
                pending ? (
                    <Spinner />
                ) : (

                    <div className="sport-structure-table">
                        <div className="top">
                            <div className="each-top">
                                <h3 className="h3-24">{ formatNumber(Number(data.teams)) }</h3>
                                <small className="p2-16">Equipos</small>
                            </div>
                            { data.roles.length > 0 && data.roles.map((rol, idx) => (
                                <div key={ `rol-${idx}` } className="each-top">
                                    <h3 className="h3-24">{ formatNumber(Number(rol.role_count)) }</h3>
                                    <small className="p2-16">{ rol.rol }</small>
                                </div>
                            )) }
                        </div>
                    </div>
                )
            }

            <Acclaration title="Detalle por deporte" text="Desglose de estructura deportiva por deporte" />

            <DataTable
                columns={ columns }
                data={ tableData }
                highlightOnHover
                pointerOnHover
                fixedHeader
                progressPending={ pending }
                progressComponent={ <Spinner /> }
            />
        </Box>
    );
};

export default SportStructureTable;
