import React from 'react'
import { startSignup } from '../../../actions/auth.action'
import useForm from '../../../hooks/useForm'
import { useHistory } from 'react-router-dom'

const SignupScreen = () => {
	const { values, handleInputChange } = useForm({})
	const { name, email, password, password2 } = values
	const history = useHistory()

	const handleSignup = async (e) => {
		e.preventDefault()
		if (password === password2) {
			const signedupUser = await startSignup(name, email, password)
			if (signedupUser) {
				history.push(`/`)
			} else {
				alert('Algo pasó con el registro de usuario')
			}

		} else {
			alert('Las contraseñas deben coincidir')
		}
	}

	return (
		<>
			<section className='signup'>
				<form onSubmit={ handleSignup }>
					<label>Nombre y apellidos</label>
					<input type='text' onChange={ handleInputChange } placeholder='Tu nombre' name='name' />
					<label>Email</label>
					<input type='email' onChange={ handleInputChange } placeholder='Tu email' name='email' />
					<label>Contraseña</label>
					<input type='password' onChange={ handleInputChange } placeholder='Contraseña' name='password' />
					<label>Repite Contraseña</label>
					<input type='password' onChange={ handleInputChange } placeholder='Repite Contraseña' name='password2' />
					<button type='submit' className='my-btn mini'>
						Registro
					</button>
				</form>
			</section>
		</>
	)
}

export default SignupScreen
