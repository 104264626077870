import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const BackButton = ({ title, link }) => {
    return (
        <Link to={ link } className="back-btn">
            <FaChevronLeft />
            <span className="p2-16">{ title }</span>
        </Link>
    )
}

export default BackButton
