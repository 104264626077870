import Swal from 'sweetalert2';
import { fetchConToken } from '../helpers/fetch';

export const updateClupikMetricTable = async () => {
	try {
		const resp = await fetchConToken(`clupik/update-metricas-rows`, 'GET');
		const body = await resp.json();
		if (!body.ok) {
			Swal.fire('Error actualizando datos en clupik', body.msg, 'error');
		} else {
			Swal.fire('Clupik actualizado con Holded y Pipe', body.msg, 'success');
		}
	} catch (error) {
		console.log(error);
		Swal.fire('Error', 'Error actualizando datos en clupik', 'error');
	}
};

export const updateLostDeals = async () => {
	try {
		const resp = await fetchConToken(`business/clientes/deals/update-lost`, 'GET');
		const body = await resp.json();
		if (!body.ok) {
			Swal.fire('Error actualizando datos de pipe', body.msg, 'error');
		}
	} catch (error) {
		console.log(error);
		Swal.fire('Error', 'Error actualizando datos de pipe', 'error');
	}
};

export const updateOrganizationData = async () => {
	const resp = await fetchConToken(`business/clientes/set-in-deals`, 'GET');
	const body = await resp.json();
	if (!body.ok) {
		Swal.fire('Error actualizando datos de pipe organización', body.msg, 'error');
	} else {
		Swal.fire('Pipe Actualizado', body.msg, 'success');
	}
};

export const updatePoints = async () => {
	try {
		const resp = await fetchConToken('pipe', {}, 'POST');
		const body = await resp.json();
		if (body.ok) {
			Swal.fire('Conteo para pipe hecho', body.msg, 'success');
		}
	} catch (error) {
		console.log(error);
		Swal.fire('Error', 'Error actualizando puntos en pipe', 'error');
	}
};
