import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { sportsCount, sectionsCount } from '../../../actions/sports.action';

import Box from '../../_ui/Box/Box';

import { colorArray } from '../../../helpers/colors';
import { formatNumber } from '../../../helpers/_helper';

import { ResponsivePie } from '@nivo/pie';
import { MdChevronRight } from 'react-icons/md';

const SportsChart = () => {
    const [sports, setSports] = useState(null);
    const [sections, setSections] = useState({ only: 0, multi: 0 });
    const isMounted = useRef(true);
    const [data, setData] = useState([]);

    const getSectionsCount = useCallback(async () => {
        let count = await sectionsCount();
        setSections(count);
    }, []);


    const getSportsCount = useCallback(async () => {
        let count = await sportsCount();
        let labels = [];
        let sportCount = [];
        count?.forEach((sport, idx) => {
            labels.push(sport.name);
            sportCount.push(sport.sport_count);
            if (data?.length === 0) {
                setData(data => [...data, { id: sport.name, label: sport.name, value: sport.sport_count, color: colorArray[idx] }]);
            }
        });
        setSports(count);
    }, [data]);


    useEffect(() => {
        if (isMounted.current) {
            getSectionsCount();
            getSportsCount();
        }
        return () => {
            isMounted.current = false;
        };
    }, [getSectionsCount, getSportsCount]);


    return (
        <Box id="sport-chart" title="Deportes">
            <Link className="client-list-link" to="/comercial/deportes"> Listado de deportes  <MdChevronRight /></Link>
            <div className="fix">
                <ResponsivePie
                    colors={ { scheme: 'paired' } }
                    data={ data }
                    margin={ { top: 24, right: 0, bottom: 80, left: 24 } }
                    innerRadius={ 0.3 }
                    activeOuterRadiusOffset={ 8 }
                    borderColor={ { from: 'color', modifiers: [['darker', '0.2']] } }
                    enableArcLinkLabels={ false }
                    arcLinkLabelsSkipAngle={ 10 }
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={ 2 }
                    arcLinkLabelsColor={ { from: 'color' } }
                    arcLabelsSkipAngle={ 10 }
                    arcLabelsTextColor={ { from: 'color', modifiers: [['darker', '3']] } }
                    legends={ [] }
                />
            </div>
            <div ref={ isMounted } className="top">
                <div className="data">
                    <h2>{ formatNumber(Number(sections.only)) }</h2>
                    <small className="p2-16">Clubs con un único deporte</small>
                </div>
                <div className="data">
                    <h2>{ formatNumber(Number(sections.multi)) }</h2>
                    <small className="p2-16">Clientes multideporte</small>
                </div>
            </div>
            <div className="bottom">
                {
                    sports && (
                        <>
                            <div className="sports-table">
                                {
                                    sports?.map(sport => (
                                        <article className="sport-row" key={ sport.id }>
                                            <h5 className="sport">{ sport.name }</h5>
                                            <span className="count p3-13">{ formatNumber(sport.sport_count) }</span>
                                        </article>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </Box>
    );
};

export default SportsChart;
